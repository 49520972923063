const COLORS = {
  URL_SHORTENER_MAIN: '#a33a08',
  HIGHLIGHT_TABLE_ROW_DANGER: '#ffefef',
  CLICK_ON_MAP: '#3333ec',
  EXCLUDED_CLICK_ON_MAP: '#ec3333',
  UNKNOWN_LINK_CATEGORY: '#954429',
  DEFAULT_ZONE_COLOR: '#358DFF',
  NUMBER_OF_CLICKS_ON_LINKS_CREATED: '#7575f6',
  NUMBER_OF_CLICKS_ON_DATE: '#02f8a1'
}

export default COLORS
