import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import SalesAgentDataStoreSagaHandlers from '../../sagas/data/SalesAgentDataStore'

const sliceName = 'salesAgentsStore'

const SalesAgentDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  SalesAgentDataStoreSagaHandlers
)

export default SalesAgentDataStoreSlice
