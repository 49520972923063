import { useMemo } from 'react'
import useNormalizedBrevoTransactionalMessageEvents from './useNormalizedBrevoTransactionalMessageEvents'

/**
 * Returns requested brevo transactional message event(s) from state.data.brevoTransactionalMessageEvents in a normalized fashion.
 * @param brevoTransactionalMessageEventIds {string | string[]}: id of requested brevo transactional message events.
 * @param asMap {boolean}: when multiple brevo transactional message events are requested, return brevo transactional message events as an object mapping brevo transactional message events by their id.
 * @param deNormalizationConfig {{}}:
 * specify which related models are merged into the brevo transactional message event object. Default config returns the same result as
 * useNormalizedBrevoTransactionalMessageEvents.
 * @param fetchAllEnabled {boolean}: enable fetching of all brevo transactional message events, when no brevo transactional message event id is passed.
 * @returns {StoreBrevoTransactionalMessageEvent | StoreBrevoTransactionalMessageEvent[]}
 */
const useBrevoTransactionalMessageEvents = (
  brevoTransactionalMessageEventIds = null,
  asMap = false,
  deNormalizationConfig = {},
  fetchAllEnabled = false
) => {
  const normalizedBrevoTransactionalMessageEvents = useNormalizedBrevoTransactionalMessageEvents(brevoTransactionalMessageEventIds, asMap, fetchAllEnabled)

  return useMemo(
    () => {
      const populateBrevoTransactionalMessageEvent = brevoTransactionalMessageEvent => brevoTransactionalMessageEvent

      return normalizedBrevoTransactionalMessageEvents && (
        typeof brevoTransactionalMessageEventIds === 'string' || typeof brevoTransactionalMessageEventIds === 'number'
          ? populateBrevoTransactionalMessageEvent(normalizedBrevoTransactionalMessageEvents)
          : asMap
            ? Object.keys(normalizedBrevoTransactionalMessageEvents)
              .reduce((brevoTransactionalMessageEvents, brevoTransactionalMessageEventId) => {
                brevoTransactionalMessageEvents[brevoTransactionalMessageEventId] = populateBrevoTransactionalMessageEvent(normalizedBrevoTransactionalMessageEvents[brevoTransactionalMessageEventId])
                return brevoTransactionalMessageEvents
              }, {})
            : normalizedBrevoTransactionalMessageEvents.map(populateBrevoTransactionalMessageEvent)
      )
    },
    [normalizedBrevoTransactionalMessageEvents, brevoTransactionalMessageEventIds, asMap]
  )
}

export default useBrevoTransactionalMessageEvents
