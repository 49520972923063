import DashboardLinksCreatedByCategoryListSlice
  from '../../slices/experiences/DashboardLinksCreatedByCategoryListSlice'
import DashboardClicksOnLinksCreatedByCategoryListSlice
  from '../../slices/experiences/DashboardClicksOnLinksCreatedByCategoryListSlice'
import DashboardClicksOnLinksByPeriodListSlice from '../../slices/experiences/DashboardClicksOnLinksByPeriodListSlice'

const DashboardExperienceActions = {
  linksCreatedByCategory: DashboardLinksCreatedByCategoryListSlice.actions,
  clicksOnLinksCreatedByCategory: DashboardClicksOnLinksCreatedByCategoryListSlice.actions,
  clicksOnLinksByPeriod: DashboardClicksOnLinksByPeriodListSlice.actions
}

export default DashboardExperienceActions
