import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useLinkCategories from './useLinkCategories'
import FilterParameter from '../../modules/utilities/list/FilterParameter'
import { ALL_VALUES_SELECT_OPTION_VALUE } from '../../constants/general'

const linkCategoryToSelectOption = ({ id, name }) => ({
  value: id,
  label: name
})

/**
 * Use link categories as select/radio input options
 * @param includeAllCategoriesOption {boolean}: whether to include the 'All categories' option
 * @returns {[{value: any, label: string}]}
 */
const useLinkCategoriesAsSelectOptions = (includeAllCategoriesOption = true) => {
  const linkCategories = useLinkCategories()
  const { t } = useTranslation()

  return useMemo(() => {
    const categorySelectOptions = linkCategories.map(linkCategoryToSelectOption)
    return includeAllCategoriesOption
      ? ([
          {
            value: ALL_VALUES_SELECT_OPTION_VALUE,
            label: t('LinkCategorySelectOptions.AllCategories', 'All categories')
          },
          ...categorySelectOptions,
          {
            value: FilterParameter.FILTER_VALUES.EMPTY,
            label: t('LinkCategorySelectOptions.Unknown', 'Unknown')
          }
        ])
      : ([
          ...categorySelectOptions,
          {
            value: FilterParameter.FILTER_VALUES.EMPTY,
            label: t('LinkCategorySelectOptions.Unknown', 'Unknown')
          }
        ])
  },
  [linkCategories, includeAllCategoriesOption, t]
  )
}

export default useLinkCategoriesAsSelectOptions
