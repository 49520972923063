import { useMemo } from 'react'
import useNormalizedClickSyncProcessLogs from './useNormalizedClickSyncProcessLogs'
import useNormalizedClickSyncProcesses from './useNormalizedClickSyncProcesses'
import uniq from 'lodash/uniq'

/**
 * Returns requested-/all clickSyncProcessLog(s) from state.data.clickSyncProcessLogs in a non-normalized fashion.
 * @param clickSyncProcessLogIds {string | string[] | null}: id of requested clickSyncProcessLogs.
 * @param asMap {boolean}: when multiple clickSyncProcessLogs are requested, return clickSyncProcessLogs as an object mapping clickSyncProcessLogs by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all clickSyncProcessLogs, when no clickSyncProcessLog id is passed.
 * @returns {StoreClickSyncProcessLog | StoreClickSyncProcessLog[]}
 */
const useClickSyncProcessLogs = (clickSyncProcessLogIds = null, asMap = false, fetchAllEnabled = false) => {
  const normalizedClickSyncProcessLogs = useNormalizedClickSyncProcessLogs(clickSyncProcessLogIds, asMap, fetchAllEnabled)
  const relatedIds = useMemo(
    () => {
      const reducedClickSyncProcessLogs = typeof clickSyncProcessLogIds === 'string'
        ? asMap
          ? normalizedClickSyncProcessLogs && { [normalizedClickSyncProcessLogs.id]: normalizedClickSyncProcessLogs }
          : normalizedClickSyncProcessLogs && [normalizedClickSyncProcessLogs]
        : normalizedClickSyncProcessLogs

      const relatedIds = (
        asMap
          ? Object.values(reducedClickSyncProcessLogs || {})
          : reducedClickSyncProcessLogs || []
      ).reduce(
        (relatedIds, clickSyncProcessLog) => {
          clickSyncProcessLog.clickSyncProcessId && relatedIds.clickSyncProcessIds.push(clickSyncProcessLog.clickSyncProcessId)
          return relatedIds
        },
        { clickSyncProcessIds: [] }
      )
      relatedIds.clickSyncProcessIds = uniq(relatedIds.clickSyncProcessIds)

      return relatedIds
    },
    [normalizedClickSyncProcessLogs, asMap, clickSyncProcessLogIds]
  )
  const clickSyncProcesses = useNormalizedClickSyncProcesses(relatedIds?.clickSyncProcessIds, true)

  return useMemo(
    () => {
      const populateClickSyncProcessLog = clickSyncProcessLog => {
        clickSyncProcessLog.clickSyncProcess = clickSyncProcesses[clickSyncProcessLog.clickSyncProcessId]

        return clickSyncProcessLog
      }

      return normalizedClickSyncProcessLogs && (
        typeof clickSyncProcessLogIds === 'string'
          ? populateClickSyncProcessLog(normalizedClickSyncProcessLogs)
          : asMap
            ? Object.keys(normalizedClickSyncProcessLogs)
              .reduce((clickSyncProcessLogs, clickSyncProcessLogId) => {
                clickSyncProcessLogs[clickSyncProcessLogId] = populateClickSyncProcessLog(normalizedClickSyncProcessLogs[clickSyncProcessLogId])
                return clickSyncProcessLogs
              }, {})
            : normalizedClickSyncProcessLogs?.map(populateClickSyncProcessLog)
      )
    },
    [normalizedClickSyncProcessLogs, clickSyncProcesses, asMap, clickSyncProcessLogIds]
  )
}

export default useClickSyncProcessLogs
