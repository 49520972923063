import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import ClickSyncProcessDataStoreSagaHandlers from '../../sagas/data/ClickSyncProcessDataStore'

const sliceName = 'clickSyncProcessesStore'

const ClickSyncProcessDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  ClickSyncProcessDataStoreSagaHandlers
)

export default ClickSyncProcessDataStoreSlice
