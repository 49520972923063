export const MAIN_HTTP_API_CONFIG = {
  URL: process.env.REACT_APP_MAIN_HTTP_API_URL
}

export const MAIN_HTTP_API_ENDPOINTS = {
  LOG_IN: '/login',
  USER: '/user/:id',
  USERS: '/user',
  LINK: '/link/:id',
  LINK_CLICKS: '/link/:id/clicks',
  LINKS: '/link',
  CLICK: '/click/:id',
  CLICKS: '/click',
  PROFILE: '/profile',
  API_KEY: '/api-key/:id',
  API_KEYS: '/api-key',
  GLOBALLY_EXCLUDED_CLICKER: '/globally-excluded-clicker/:id',
  GLOBALLY_EXCLUDED_CLICKERS: '/globally-excluded-clicker',
  MESSAGE_CLIENTS: '/message-client',
  MESSAGE_CLIENT_LINKS: '/message-client/:id/links',
  MESSAGE_CLIENT_BREVO_TRANSACTIONAL_MESSAGE_EVENTS: '/message-client/:id/transactional-events',
  BREVO_TRANSACTIONAL_MESSAGE_EVENTS: '/transactional-event',
  LINK_CATEGORIES: '/link-category',
  SALES_AGENTS: '/sales-agent',
  LINK_STATISTICS: {
    CREATED_BY_CATEGORY: '/link-statistics/created-by-category',
    CREATED_BY_CATEGORY_NUMBER_OF_CLICKS: '/link-statistics/created-by-category/number-of-clicks',
    NUMBER_OF_CLICKS_BY_PERIOD: '/link-statistics/number-of-clicks-by-period'
  },
  CLICK_SYNC_PROCESSES: '/click-sync-process',
  CLICK_SYNC_PROCESS_LOGS: '/click-sync-process-log',
  CLICK_SYNC_SETTINGS: '/click-sync-settings',
  ZONE: '/zone/:id',
  ZONES: '/zone'
}
