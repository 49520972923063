/**
 * Calculates a color from a string.
 * https://mui.com/material-ui/react-avatar/
 * @param string {string}: the string converted to color.
 * @return {string}: hash code of the color.
 */
const stringToColor = string => {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

/**
 * Extracts red, green and blue values from a hex string.
 * @param hex {string}: hex color string with the # included.
 * @returns {{red: number, green: number, blue: number}}
 */
const hexToRGB = hex => ({
  red: parseInt(hex.substring(1, 3), 16),
  green: parseInt(hex.substring(3, 5), 16),
  blue: parseInt(hex.substring(5), 16)
})

/**
 * Returns black or white depending on which has better contrast with the provided rgb value.
 * @param red {number}
 * @param green {number}
 * @param blue {number}
 * @returns {string}: hex string of black or white.
 */
const getTextColorForBackgroundColor = (red, green, blue) => red * 0.299 + green * 0.587 + blue * 0.114 > 186
  ? '#000000'
  : '#ffffff'

/**
 * Returns white or black depending on which has better contrast with the provided hex color value.
 * @param backgroundColorHex {string}: hex color string with the # included.
 * @returns {string}: hex string of black or white.
 */
const getTextColorForHexBackgroundColor = backgroundColorHex => {
  const { red, green, blue } = hexToRGB(backgroundColorHex)

  return getTextColorForBackgroundColor(red, green, blue)
}

const ColorUtilities = {
  stringToColor,
  hexToRGB,
  getTextColorForBackgroundColor,
  getTextColorForHexBackgroundColor
}

export default ColorUtilities
