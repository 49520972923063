import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import ClickSyncProcessLogDataStoreSagaHandlers from '../../sagas/data/ClickSyncProcessLogDataStore'

const sliceName = 'clickSyncProcessLogsStore'

const ClickSyncProcessLogDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  ClickSyncProcessLogDataStoreSagaHandlers
)

export default ClickSyncProcessLogDataStoreSlice
