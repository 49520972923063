class StoreZone {
  constructor (
    id = undefined,
    name,
    description,
    centerLatitude,
    centerLongitude,
    radius,
    color,
    createdAt
  ) {
    this.id = id
    this.name = name
    this.description = description
    this.centerLatitude = centerLatitude
    this.centerLongitude = centerLongitude
    this.radius = radius
    this.color = color
    this.createdAt = new Date(createdAt)
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      name: this.name,
      description: this.description,
      centerLatitude: this.centerLatitude,
      centerLongitude: this.centerLongitude,
      radius: this.radius,
      color: this.color
      // createdAt is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    name,
    description,
    centerLatitude,
    centerLongitude,
    radius,
    color,
    createdAt
  }) {
    return new StoreZone(
      id,
      name,
      description,
      centerLatitude,
      centerLongitude,
      radius,
      color,
      createdAt
    )
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      centerLatitude: this.centerLatitude,
      centerLongitude: this.centerLongitude,
      radius: this.radius,
      color: this.color,
      createdAt: this.createdAt
    }
  }

  static FromJSON ({
    id,
    name,
    description,
    centerLatitude,
    centerLongitude,
    radius,
    color,
    createdAt
  }) {
    return new StoreZone(
      id,
      name,
      description,
      centerLatitude,
      centerLongitude,
      radius,
      color,
      createdAt
    )
  }
}

export default StoreZone
