import { put } from 'redux-saga/effects'
import LayoutActions from '../../../actions/Layout'
import ListMetaData from '../../../../modules/utilities/list/ListMetaData'
import FilterExpression from '../../../../modules/utilities/list/FilterExpression'
import FilterParameter from '../../../../modules/utilities/list/FilterParameter'
import ErrorActions from '../../../actions/Error'
import OLError from '../../../../modules/errorHandling/models/OLError'
import ListFetchResult from '../../../../modules/utilities/list/ListFetchResult'
import DataStoresActions from '../../../actions/data'
import { NORMALIZATION_ENTITY_NAME_TO_STORE_ENTITY_NAME_MAPPING } from '../../../mappings'
import { IS_DEV_ENV } from '../../../../constants/general'

const createDataStoreSliceFetchHandlers =
/**
   * Creates general-purpose fetch handlers for data-store slices.
   * These handlers can be used in case of basic data-stores when fetching is simple, schematic and straight-forward,
   * without any side effects.
   * @param dataName {string}: data-store item name.
   * @param DataService {{FindMany: function(listMetaData: ListMetaData): Promise<AxiosResponse<object[]>>}}: service
   * handling the fetch of data-store items.
   * @param fetchResponseNormalizationSchema {object}: the normalization schema of the data received from fetch.
   * @param mergedEntityNames {string}: name of the entities that will be normalized, and merged into their data store.
   * @param fetchIdRESTParameterName {string}: name of the parameter in which the ids will be sent with the fetch request.
   * @param buildExtraFilterParameters {func: FilterParameter[]}: build extra filter parameters, that are passed to the
   * fetch request. The redux action is passed as the first parameter for the function. It must return an array
   * containing the extra filter parameters.
   * @returns {function({types: {FETCH: string, FETCH_RESULT: string, MERGE: string, RESET: string}, reducer: ((function(*=, *): *)|*), actions: {fetch: function(ids: string[]): object, fetchResult: function(result: AxiosResponse<object[]>, callback: function(storeValue: object)): object, merge: function(data: {}): object, reset: function(): object}, saga: ((function(): Generator<SimpleEffect<"FORK", ForkEffectDescriptor<never>>, void, *>)|*)}) : {fetch: ((function({type: string, data: {ids: (string[]|null)}}): Generator<*, void, *>)|*), fetchResult: ((function({type: string, data: {result: AxiosResponse<Object[]>, callback: (function(Object))}}): Generator<*, void, *>)|*), fetchId: string}}
   */
    (
      dataName, DataService, fetchResponseNormalizationSchema, mergedEntityNames, fetchIdRESTParameterName = 'id',
      buildExtraFilterParameters = null
    ) => {
      const fetchId = `[${dataName}] DataStoreSagaHandlers.fetch`

      /**
     * Fetch handler saga creator for a data-store slice.
     * @param DataStoreSlice {{types: {FETCH: string, FETCH_RESULT: string, MERGE: string, RESET: string}, reducer: ((function(*=, *): *)|*), actions: {fetch: function(ids: string[]): object, fetchResult: function(result: AxiosResponse<object[]>, callback: function(storeValue: object)): object, merge: function(data: {}): object, reset: function(): object}, saga: ((function(): Generator<SimpleEffect<"FORK", ForkEffectDescriptor<never>>, void, *>)|*)}}:
     * data-store slice for which the fetch handlers are created.
     * @returns {function(string, {FindMany: (function(ListMetaData): Promise<AxiosResponse<Object[]>>)}, string, Object, string=): {fetch: function({type: string, data: {ids: (string[]|null)}}): Generator<*, void, *>, fetchResult: function({type: string, data: {result: AxiosResponse<Object[]>, callback: (function(Object))}}): Generator<*, void, *>, fetchId: string}}
     */
      return DataStoreSlice => ({
      /**
       * @param action {{type: string, data: { ids: string[] | null, extraParams: [], onFinish: function }}}
       * @returns {Generator<*, void, *>}
       */
        fetch: function * (action) {
          try {
            yield put(LayoutActions.setIsLoading(true, fetchId))
            const { data: { ids } } = action

            const result = yield DataService.FindMany(
              new ListMetaData(
                ListMetaData.NO_LIMIT, // fetch all requested items(without pagination)
                0,
                undefined,
                ids // if ids are passed, fetch specified items
                  ? FilterExpression.AND(
                    FilterParameter.IN(fetchIdRESTParameterName, ids),
                    ...((buildExtraFilterParameters && buildExtraFilterParameters(action)) || [])
                  )
                  : null // if not passed, we consider it as all items are requested, so no filtering is applied...
              )
            )
            yield put(DataStoreSlice.actions.fetchResult(result))
          } catch (e) {
            yield put(ErrorActions.trigger(
              new OLError(
              `[[${dataName}] DataStoreSagaHandlers.fetch]: Failed to fetch the requested ${dataName}(s)`, `Failed to fetch the requested ${dataName}(s)`,
              e
              ),
              true,
              true,
              true,
              false
            ))
          }
          try {
            typeof action.data.onFinish === 'function' && action.data.onFinish()
          } catch (e) {
            if (IS_DEV_ENV) {
              console.error(`${fetchId} The following error occurred during the execution of the 'action.onFinish': ${e}`)
            }
          }
          yield put(LayoutActions.setIsLoading(false, fetchId))
        },
        /**
       * @param action {{type: string, data: { result: AxiosResponse<object[]>, callback: function(storeValue: object) }}}
       * @returns {Generator<*, void, *>}
       */
        fetchResult: function * (action) {
          try {
            const { data: { result, callback } } = action

            // normalize dataStore item fetch result from AxiosResponse
            const storeValues = ListFetchResult.FromAxiosResponse(result).toStoreValues(
              fetchResponseNormalizationSchema,
              mergedEntityNames.reduce(
                (entityMapping, entityName) => {
                  if (!NORMALIZATION_ENTITY_NAME_TO_STORE_ENTITY_NAME_MAPPING[entityName]) {
                    throw new Error(`[createDataStoreSliceFetchHandlers] Can't associate entity name "${entityName}" with a store inside fetch handler.`)
                  }
                  entityMapping[entityName] = NORMALIZATION_ENTITY_NAME_TO_STORE_ENTITY_NAME_MAPPING[entityName].FromNormalizedRESTModel
                  return entityMapping
                },
                {}
              )
            )
            // merge items extracted during normalization(toStoreValues(...)), into their own data store
            yield put(
              DataStoresActions.mergeEntitiesIntoStores(
                mergedEntityNames.reduce(
                  (mergedEntities, entityName) => {
                    mergedEntities[entityName] = storeValues.entities[entityName]
                    return mergedEntities
                  },
                  {}
                )
              )
            )
            if (callback) {
              yield callback(storeValues)
            } // it is important to execute the callback last so the previously dispatched actions can take their own effect before the callback being executed
          } catch (e) {
            yield put(ErrorActions.trigger(
              new OLError(
              `[[${dataName}] DataStoreSagaHandlers.fetchResult]: Failed to process the fetched ${dataName}(s)`,
              `Failed to process the fetched ${dataName}(s)`,
              e
              ),
              true,
              true,
              true,
              false
            ))
          }
        },
        fetchId
      })
    }

export default createDataStoreSliceFetchHandlers
