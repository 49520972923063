const selectClickSyncProcessLogs = state => state.data.clickSyncProcessLogs

const selectClickSyncProcessLogById = clickSyncProcessLogId => state => state.data.clickSyncProcessLogs[clickSyncProcessLogId]

const ClickSyncProcessLogDataStoreSelectors = {
  clickSyncProcessLogs: selectClickSyncProcessLogs,
  clickSyncProcessLogById: selectClickSyncProcessLogById
}

export default ClickSyncProcessLogDataStoreSelectors
