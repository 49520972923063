import { useMemo } from 'react'
import useTransactionalMessageEvents from './useTransactionalMessageEvents'
import { useTranslation } from 'react-i18next'
import { ALL_VALUES_SELECT_OPTION_VALUE, TRANSACTIONAL_MESSAGE_EVENT_ID } from '../../constants/general'

const transactionalMessageEventToSelectOption = ({ id, name }) => ({
  value: id,
  label: name
})

/**
 * Use transactional message events as select/radio input options
 * @param includeAllTransactionalMessageEventsOption {boolean}: whether to include the 'All transactional message events' option
 * @returns {[{value: any, label: string}]}
 */
const useTransactionalMessageEventsAsSelectOptions = (includeAllTransactionalMessageEventsOption = false) => {
  const transactionalMessageEvents = useTransactionalMessageEvents()
  const { t } = useTranslation()

  return useMemo(() => {
    const transactionalMessageEventSelectOptions = transactionalMessageEvents
      .filter(({ id }) => id !== TRANSACTIONAL_MESSAGE_EVENT_ID.UNIQUE_OPEN_BY_A_PROXY)
      .map(transactionalMessageEventToSelectOption)

    return includeAllTransactionalMessageEventsOption
      ? ([
          {
            value: ALL_VALUES_SELECT_OPTION_VALUE,
            label: t('TransactionalMessageEventSelectOptions.Labels.AllTransactionalMessageEvents', 'All events')
          },
          ...transactionalMessageEventSelectOptions
        ])
      : transactionalMessageEventSelectOptions
  },
  [t, transactionalMessageEvents, includeAllTransactionalMessageEventsOption]
  )
}

export default useTransactionalMessageEventsAsSelectOptions
