import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import LinkCategoryDataStoreSagaHandlers from '../../sagas/data/LinkCategoryDataStore'

const sliceName = 'linkCategoriesStore'

const LinkCategoryDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  LinkCategoryDataStoreSagaHandlers
)

export default LinkCategoryDataStoreSlice
