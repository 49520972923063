class StoreClickSyncProcessLog {
  constructor (
    id = undefined,
    clickSyncProcessId,
    executionStatus,
    executionDate,
    executionMessage
  ) {
    this.id = id
    this.clickSyncProcessId = clickSyncProcessId
    this.clickSyncProcess = null
    this.executionStatus = executionStatus
    this.executionDate = executionDate
    this.executionMessage = executionMessage
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      // clickSyncProcessId is read-only
      // executionStatus is read-only
      // executionDate is read-only
      // executionMessage is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    clickSyncProcessId,
    executionStatus,
    executionDate,
    executionMessage
  }) {
    return new StoreClickSyncProcessLog(
      id,
      clickSyncProcessId,
      executionStatus,
      executionDate,
      executionMessage
    )
  }

  toJSON () {
    return {
      id: this.id,
      clickSyncProcessId: this.clickSyncProcessId,
      executionStatus: this.executionStatus,
      executionDate: this.executionDate,
      executionMessage: this.executionMessage
    }
  }

  static FromJSON ({
    id,
    clickSyncProcessId,
    executionStatus,
    executionDate,
    executionMessage
  }) {
    return new StoreClickSyncProcessLog(
      id,
      clickSyncProcessId,
      executionStatus,
      executionDate,
      executionMessage
    )
  }
}

export default StoreClickSyncProcessLog
