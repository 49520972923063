const selectClickSyncProcesses = state => state.data.clickSyncProcesses

const selectClickSyncProcessById = clickSyncProcessId => state => state.data.clickSyncProcesses[clickSyncProcessId]

const ClickSyncProcessDataStoreSelectors = {
  clickSyncProcesses: selectClickSyncProcesses,
  clickSyncProcessById: selectClickSyncProcessById
}

export default ClickSyncProcessDataStoreSelectors
