import { makeActionCreator } from '../../../modules/utilities/store'
import CLICK_SYNC_SETTINGS_EXPERIENCE_TYPES from '../../types/experiences/ClickSyncSettings'

const ClickSyncSettingsExperienceActions = {
  /**
   * @returns {{ type: string, data: { settings: object } }}
   */
  fetch: makeActionCreator(CLICK_SYNC_SETTINGS_EXPERIENCE_TYPES.FETCH),
  /**
   * @param settings {object}
   * @returns {{ type: string, data: { settings: object } }}
   */
  set: makeActionCreator(CLICK_SYNC_SETTINGS_EXPERIENCE_TYPES.SET, 'settings'),
  /**
   * @param settingsItem {StoreClickSyncSettings}
   * @returns {{ type: string, data: { settings: object } }}
   */
  save: makeActionCreator(CLICK_SYNC_SETTINGS_EXPERIENCE_TYPES.SAVE, 'settingsItem'),
  /**
   * @returns {{ type: string, data: { settings: object } }}
   */
  reset: makeActionCreator(CLICK_SYNC_SETTINGS_EXPERIENCE_TYPES.RESET)
}

export default ClickSyncSettingsExperienceActions
