import { combineReducers } from 'redux'
import ClickSyncProcessLogManagementClickSyncProcessLogListSlice from '../../slices/experiences/ClickSyncProcessLogManagementClickSyncProcessLogListSlice'
import ClickSyncProcessLogManagementClickSyncProcessLogListExportSlice from '../../slices/experiences/ClickSyncProcessLogManagementClickSyncProcessLogListExportSlice'

const ClickSyncProcessLogManagementExperienceStore = combineReducers({
  list: ClickSyncProcessLogManagementClickSyncProcessLogListSlice.reducer,
  exportList: ClickSyncProcessLogManagementClickSyncProcessLogListExportSlice.reducer
})

export default ClickSyncProcessLogManagementExperienceStore
