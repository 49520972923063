import ColorUtilities from '../../modules/utilities/color'

class StoreLinkCategory {
  constructor (
    id = undefined,
    name,
    color
  ) {
    this.id = id
    this.name = name
    this.color = color || ColorUtilities.stringToColor(name || '-')
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      // name is read-only
      // color is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    name,
    color
  }) {
    return new StoreLinkCategory(
      id,
      name,
      color
    )
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      color: this.color
    }
  }

  static FromJSON ({
    id,
    name,
    color
  }) {
    return new StoreLinkCategory(
      id,
      name,
      color
    )
  }
}

export default StoreLinkCategory
