import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Get click sync settings.
 * @returns {Promise<AxiosResponse<object>>}: click sync settings.
 * @constructor
 */
const Get = async () => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.CLICK_SYNC_SETTINGS
)

/**
 * Save click sync settings.
 * @param clickSyncSettingsData {object}: Click sync settings data.
 * @returns {Promise<AxiosResponse<object>>}: saved click sync settings.
 * @constructor
 */
const Save = async clickSyncSettingsData => mainHTTPClient.put(
  MAIN_HTTP_API_ENDPOINTS.CLICK_SYNC_SETTINGS,
  clickSyncSettingsData
)

const ClickSyncSettingsService = {
  Get,
  Save
}

export default ClickSyncSettingsService
