import { useMemo } from 'react'
import useClickSyncExecutionStatuses from './useClickSyncExecutionStatuses'
import { useTranslation } from 'react-i18next'
import { ALL_VALUES_SELECT_OPTION_VALUE } from '../../constants/general'

const clickSyncExecutionStatusToSelectOption = ({ id, name, color }) => ({
  value: id,
  label: name,
  color
})

/**
 * Use click sync execution statuses as select/radio input options
 * @param includeAllClickSyncExecutionStatuses {boolean}: whether to include the 'All click sync execution statuses' option
 * @returns {[{value: any, label: string}]}
 */
const useClickSyncExecutionStatusesAsSelectOptions = (includeAllClickSyncExecutionStatuses = false) => {
  const clickSyncExecutionStatuses = useClickSyncExecutionStatuses()
  const { t } = useTranslation()

  return useMemo(() => {
    const clickSyncExecutionStatusSelectOptions = clickSyncExecutionStatuses.map(clickSyncExecutionStatusToSelectOption)
    return includeAllClickSyncExecutionStatuses
      ? ([
          {
            value: ALL_VALUES_SELECT_OPTION_VALUE,
            label: t('ClickSyncExecutionStatusSelectOptions.Labels.AllClickSyncExecutionStatuses', 'ALL STATUSES')
          },
          ...clickSyncExecutionStatusSelectOptions
        ])
      : clickSyncExecutionStatusSelectOptions
  },
  [t, clickSyncExecutionStatuses, includeAllClickSyncExecutionStatuses]
  )
}

export default useClickSyncExecutionStatusesAsSelectOptions
