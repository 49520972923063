import useDataStoreItemsFetchedOnce from './helpers/useDataStoreItemsFetchedOnce'
import LinkCategoryDataStoreSelectors from '../selectors/data/LinkCategories'
import LinkCategoryDataStoreSlice from '../slices/data/LinkCategoryDataStoreSlice'
import StoreLinkCategory from '../models/LinkCategory'

const JSONStoreLinkCategoryToStoreLinkCategory = JSONStoreLinkCategory => StoreLinkCategory.FromJSON(JSONStoreLinkCategory)

/**
 * Returns requested-/all link category(s) from state.data.linkCategories.
 * @param linkCategoryIds {string | string[] | null}: id of requested linkCategories. If null, all linkCategories are requested.
 * @param asMap {boolean}: when multiple linkCategories are requested, return linkCategories as an object mapping linkCategories by their id.
 * @returns {StoreLinkCategory | StoreLinkCategory[]}
 */
const useNormalizedLinkCategories = (linkCategoryIds = null, asMap = false) => useDataStoreItemsFetchedOnce(
  linkCategoryIds,
  LinkCategoryDataStoreSelectors.linkCategories,
  LinkCategoryDataStoreSlice.actions.fetch,
  JSONStoreLinkCategoryToStoreLinkCategory,
  asMap
)

export default useNormalizedLinkCategories
