import { schema } from 'normalizr'

// language
const LANGUAGE_ENTITY_NAME = 'languages'
const language = new schema.Entity(LANGUAGE_ENTITY_NAME)

// role
const ROLE_ENTITY_NAME = 'roles'
const role = new schema.Entity(ROLE_ENTITY_NAME)

// user
const USER_ENTITY_NAME = 'users'
const user = new schema.Entity(
  USER_ENTITY_NAME,
  {
    role
  },
  {
    processStrategy: value => ({
      roleId: value.role,
      ...value
    })
  }
)

// Brevo transactional message event
const BREVO_TRANSACTIONAL_MESSAGE_EVENT_ENTITY_NAME = 'brevoTransactionalMessageEvents'
const brevoTransactionalMessageEvent = new schema.Entity(BREVO_TRANSACTIONAL_MESSAGE_EVENT_ENTITY_NAME)

// sales agent
const BREVO_SALES_AGENT_ENTITY_NAME = 'salesAgents'
const salesAgent = new schema.Entity(BREVO_SALES_AGENT_ENTITY_NAME)

// message client
const MESSAGE_CLIENT_ENTITY_NAME = 'messageClients'
const messageClient = new schema.Entity(
  MESSAGE_CLIENT_ENTITY_NAME,
  {
    salesAgent
  },
  {
    processStrategy: value => ({
      salesAgentId: value.salesAgentId || value.salesAgent?.id,
      ...value
    })
  }
)

// link category
const BREVO_LINK_CATEGORY_ENTITY_NAME = 'linkCategories'
const linkCategory = new schema.Entity(BREVO_LINK_CATEGORY_ENTITY_NAME)

// link
const LINK_ENTITY_NAME = 'links'
const link = new schema.Entity(
  LINK_ENTITY_NAME,
  {
    generatedByUser: user,
    intendedForClient: messageClient,
    category: linkCategory,
    generatedBySalesAgent: salesAgent
  },
  {
    processStrategy: value => ({
      generatedBy: value.generatedByUserId || value.generatedByUser?.id,
      intendedForClientId: value.intendedForClientId || value.intendedForClient?.id,
      categoryId: value.categoryId || value.category?.id,
      generatedBySalesAgentId: value.generatedBySalesAgentId || value.generatedBySalesAgent?.id,
      ...value
    })
  }
)

// click
const CLICK_ENTITY_NAME = 'clicks'
const click = new schema.Entity(CLICK_ENTITY_NAME)

// profile
const PROFILE_ENTITY_NAME = 'profiles'
const profile = new schema.Entity(
  PROFILE_ENTITY_NAME,
  {
    language,
    role
  },
  {
    processStrategy: value => ({
      languageId: value.language?.id,
      roleId: value.role,
      ...value
    })
  }
)

// api key
const API_KEY_ENTITY_NAME = 'apiKeys'
const apiKey = new schema.Entity(API_KEY_ENTITY_NAME)

// globallyExcludedClicker
const GLOBALLY_EXCLUDED_CLICKER_ENTITY_NAME = 'globallyExcludedClickers'
const globallyExcludedClicker = new schema.Entity(GLOBALLY_EXCLUDED_CLICKER_ENTITY_NAME)

// clickSyncProcess
const CLICK_SYNC_PROCESS_ENTITY_NAME = 'clickSyncProcesses'
const clickSyncProcess = new schema.Entity(CLICK_SYNC_PROCESS_ENTITY_NAME)

// clickSyncProcessLog
const CLICK_SYNC_PROCESS_LOG_ENTITY_NAME = 'clickSyncProcessLogs'
const clickSyncProcessLog = new schema.Entity(
  CLICK_SYNC_PROCESS_LOG_ENTITY_NAME,
  {
    clickSyncProcess
  },
  {
    processStrategy: value => ({
      clickSyncProcessId: value.clickSyncProcessId || value.clickSyncProcess?.id,
      ...value
    })
  }
)

// zones
const ZONE_ENTITY_NAME = 'zones'
const zone = new schema.Entity(ZONE_ENTITY_NAME)

const RESTModelNormalizationSchemas = {
  language: {
    entityName: LANGUAGE_ENTITY_NAME,
    schema: language
  },
  profile: {
    entityName: PROFILE_ENTITY_NAME,
    schema: profile
  },
  role: {
    entityName: ROLE_ENTITY_NAME,
    schema: role
  },
  user: {
    entityName: USER_ENTITY_NAME,
    schema: user
  },
  link: {
    entityName: LINK_ENTITY_NAME,
    schema: link
  },
  click: {
    entityName: CLICK_ENTITY_NAME,
    schema: click
  },
  apiKey: {
    entityName: API_KEY_ENTITY_NAME,
    schema: apiKey
  },
  globallyExcludedClicker: {
    entityName: GLOBALLY_EXCLUDED_CLICKER_ENTITY_NAME,
    schema: globallyExcludedClicker
  },
  messageClient: {
    entityName: MESSAGE_CLIENT_ENTITY_NAME,
    schema: messageClient
  },
  brevoTransactionalMessageEvent: {
    entityName: BREVO_TRANSACTIONAL_MESSAGE_EVENT_ENTITY_NAME,
    schema: brevoTransactionalMessageEvent
  },
  linkCategory: {
    entityName: BREVO_LINK_CATEGORY_ENTITY_NAME,
    schema: linkCategory
  },
  salesAgent: {
    entityName: BREVO_SALES_AGENT_ENTITY_NAME,
    schema: salesAgent
  },
  clickSyncProcess: {
    entityName: CLICK_SYNC_PROCESS_ENTITY_NAME,
    schema: clickSyncProcess
  },
  clickSyncProcessLog: {
    entityName: CLICK_SYNC_PROCESS_LOG_ENTITY_NAME,
    schema: clickSyncProcessLog
  },
  zone: {
    entityName: ZONE_ENTITY_NAME,
    schema: zone
  }
}

export default RESTModelNormalizationSchemas
