class StoreBrevoTransactionalMessageEvent {
  constructor (
    id = undefined,
    clientId,
    type,
    brevoEventId,
    brevoWebhookId,
    brevoMessageId,
    brevoEventDate,
    brevoLink,
    brevoUserAgent,
    brevoDevice,
    brevoContactId,
    brevoMirrorLink,
    createdAt
  ) {
    this.id = id
    this.clientId = clientId
    this.client = null
    this.type = type
    this.brevoEventId = brevoEventId
    this.brevoWebhookId = brevoWebhookId
    this.brevoMessageId = brevoMessageId
    this.brevoEventDate = brevoEventDate
    this.brevoLink = brevoLink
    this.brevoUserAgent = brevoUserAgent
    this.brevoDevice = brevoDevice
    this.brevoContactId = brevoContactId
    this.brevoMirrorLink = brevoMirrorLink
    this.createdAt = createdAt
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      // clientId is read-only
      // type is read-only
      // brevoEventId is read-only
      // brevoWebhookId is read-only
      // brevoMessageId is read-only
      // brevoEventDate is read-only
      // brevoLink is read-only
      // brevoUserAgent is read-only
      // brevoDevice is read-only
      // brevoContactId is read-only
      // brevoMirrorLink is read-only
      // createdAt is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    clientId,
    type,
    brevoEventId,
    brevoWebhookId,
    brevoMessageId,
    brevoEventDate,
    brevoLink,
    brevoUserAgent,
    brevoDevice,
    brevoContactId,
    brevoMirrorLink,
    createdAt
  }) {
    return new StoreBrevoTransactionalMessageEvent(
      id,
      clientId,
      type,
      brevoEventId,
      brevoWebhookId,
      brevoMessageId,
      brevoEventDate,
      brevoLink,
      brevoUserAgent,
      brevoDevice,
      brevoContactId,
      brevoMirrorLink,
      createdAt
    )
  }

  toJSON () {
    return {
      id: this.id,
      clientId: this.clientId,
      type: this.type,
      brevoEventId: this.brevoEventId,
      brevoWebhookId: this.brevoWebhookId,
      brevoMessageId: this.brevoMessageId,
      brevoEventDate: this.brevoEventDate,
      brevoLink: this.brevoLink,
      brevoUserAgent: this.brevoUserAgent,
      brevoDevice: this.brevoDevice,
      brevoContactId: this.brevoContactId,
      brevoMirrorLink: this.brevoMirrorLink,
      createdAt: this.createdAt
    }
  }

  static FromJSON ({
    id,
    clientId,
    type,
    brevoEventId,
    brevoWebhookId,
    brevoMessageId,
    brevoEventDate,
    brevoLink,
    brevoUserAgent,
    brevoDevice,
    brevoContactId,
    brevoMirrorLink,
    createdAt
  }) {
    return new StoreBrevoTransactionalMessageEvent(
      id,
      clientId,
      type,
      brevoEventId,
      brevoWebhookId,
      brevoMessageId,
      brevoEventDate,
      brevoLink,
      brevoUserAgent,
      brevoDevice,
      brevoContactId,
      brevoMirrorLink,
      createdAt
    )
  }
}

export default StoreBrevoTransactionalMessageEvent
