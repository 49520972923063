import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import MessageClientDataStoreSagaHandlers from '../../sagas/data/MessageClientDataStore'

const sliceName = 'messageClientsStore'

const MessageClientDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  MessageClientDataStoreSagaHandlers
)

export default MessageClientDataStoreSlice
