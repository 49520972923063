class StoreMessageClient {
  constructor (
    id = undefined,
    email,
    nrOfEmailsSent,
    nrOfEmailsDelivered,
    nrOfEmailsOpened,
    nrOfEmailsClicked,
    lastEmailEventDate,
    totalLinksSent,
    totalClicks,
    totalUniqueClicks,
    lastClickDate,
    brevoTransactionalMessageEventIds,
    linkIds,
    createdAt,
    salesAgentId
  ) {
    this.id = id
    this.email = email
    this.nrOfEmailsSent = nrOfEmailsSent
    this.nrOfEmailsDelivered = nrOfEmailsDelivered
    this.nrOfEmailsOpened = nrOfEmailsOpened
    this.nrOfEmailsClicked = nrOfEmailsClicked
    this.lastEmailEventDate = lastEmailEventDate
    this.totalLinksSent = totalLinksSent
    this.totalClicks = totalClicks
    this.totalUniqueClicks = totalUniqueClicks
    this.lastClickDate = lastClickDate
    this.brevoTransactionalMessageEventIds = brevoTransactionalMessageEventIds
    this.brevoTransactionalMessageEvents = null
    this.linkIds = linkIds
    this.links = null
    this.createdAt = createdAt
    this.salesAgentId = salesAgentId
    this.salesAgent = null
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      // email is read-only
      // nrOfEmailsSent is read-only
      // nrOfEmailsDelivered is read-only
      // nrOfEmailsOpened is read-only
      // nrOfEmailsClicked is read-only
      // lastEmailEventDate is read-only
      // totalLinksSent is read-only
      // totalClicks is read-only
      // totalUniqueClicks is read-only
      // lastClickDate is read-only
      // brevoTransactionalMessageEventIds is read-only
      // linkIds is read-only
      // createdAt is read-only
      // salesAgentId is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    email,
    nrOfEmailsSent,
    nrOfEmailsDelivered,
    nrOfEmailsOpened,
    nrOfEmailsClicked,
    lastEmailEventDate,
    totalLinksSent,
    totalClicks,
    totalUniqueClicks,
    lastClickDate,
    brevoTransactionalMessageEventIds,
    linkIds,
    createdAt,
    salesAgentId
  }) {
    return new StoreMessageClient(
      id,
      email,
      nrOfEmailsSent,
      nrOfEmailsDelivered,
      nrOfEmailsOpened,
      nrOfEmailsClicked,
      lastEmailEventDate,
      totalLinksSent,
      totalClicks,
      totalUniqueClicks,
      lastClickDate,
      brevoTransactionalMessageEventIds,
      linkIds,
      createdAt,
      salesAgentId
    )
  }

  toJSON () {
    return {
      id: this.id,
      email: this.email,
      nrOfEmailsSent: this.nrOfEmailsSent,
      nrOfEmailsDelivered: this.nrOfEmailsDelivered,
      nrOfEmailsOpened: this.nrOfEmailsOpened,
      nrOfEmailsClicked: this.nrOfEmailsClicked,
      lastEmailEventDate: this.lastEmailEventDate,
      totalLinksSent: this.totalLinksSent,
      totalClicks: this.totalClicks,
      totalUniqueClicks: this.totalUniqueClicks,
      lastClickDate: this.lastClickDate,
      brevoTransactionalMessageEventIds: this.brevoTransactionalMessageEventIds,
      linkIds: this.linkIds,
      createdAt: this.createdAt,
      salesAgentId: this.salesAgentId
    }
  }

  static FromJSON ({
    id,
    email,
    nrOfEmailsSent,
    nrOfEmailsDelivered,
    nrOfEmailsOpened,
    nrOfEmailsClicked,
    lastEmailEventDate,
    totalLinksSent,
    totalClicks,
    totalUniqueClicks,
    lastClickDate,
    brevoTransactionalMessageEventIds,
    linkIds,
    createdAt,
    salesAgentId
  }) {
    return new StoreMessageClient(
      id,
      email,
      nrOfEmailsSent,
      nrOfEmailsDelivered,
      nrOfEmailsOpened,
      nrOfEmailsClicked,
      lastEmailEventDate,
      totalLinksSent,
      totalClicks,
      totalUniqueClicks,
      lastClickDate,
      brevoTransactionalMessageEventIds,
      linkIds,
      createdAt,
      salesAgentId
    )
  }
}

export default StoreMessageClient
