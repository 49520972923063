const selectLinkCategories = state => state.data.linkCategories

const selectLinkCategoryById = linkCategoryId => state => state.data.linkCategories[linkCategoryId]

const LinkCategoryDataStoreSelectors = {
  linkCategories: selectLinkCategories,
  linkCategoryById: selectLinkCategoryById
}

export default LinkCategoryDataStoreSelectors
