import LinkService from '../../../services/Link'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const LinkDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'link',
  LinkService,
  RESTModelNormalizationSchemas.link.schema,
  [
    RESTModelNormalizationSchemas.link.entityName,
    RESTModelNormalizationSchemas.user.entityName,
    RESTModelNormalizationSchemas.linkCategory.entityName,
    RESTModelNormalizationSchemas.salesAgent.entityName
  ]
)

export default LinkDataStoreSagaHandlers
