import { combineReducers } from 'redux'
import AuthExperienceStore from './Auth'
import DashboardExperienceStore from './Dashboard'
import ClickSyncProcessManagementExperienceStore from './ClickSyncProcessManagement'
import ClickSyncProcessLogManagementExperienceStore from './ClickSyncProcessLogManagement'
import ClickSyncSettingsExperienceStore from './ClickSyncSettings'
import ZoneManagementExperienceStore from './ZoneManagement'

const ExperiencesStore = combineReducers({
  auth: AuthExperienceStore,
  dashboard: DashboardExperienceStore,
  clickSyncProcessManagement: ClickSyncProcessManagementExperienceStore,
  clickSyncProcessLogManagement: ClickSyncProcessLogManagementExperienceStore,
  clickSyncSettings: ClickSyncSettingsExperienceStore,
  zoneManagement: ZoneManagementExperienceStore
})

export default ExperiencesStore
