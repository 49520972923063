import { all, fork } from 'redux-saga/effects'
import ErrorSaga from './Error'
import UserDataStoreSlice from '../slices/data/UserDataStoreSlice'
import LinkDataStoreSlice from '../slices/data/LinkDataStoreSlice'
import ClickDataStoreSlice from '../slices/data/ClickDataStoreSlice'
import LanguageDataStoreSlice from '../slices/data/LanguageDataStoreSlice'
import ApiKeyDataStoreSlice from '../slices/data/ApiKeyDataStoreSlice'
import GloballyExcludedClickerDataStoreSlice from '../slices/data/GloballyExcludedClickerDataStoreSlice'
import MessageClientDataStoreSlice from '../slices/data/MessageClientDataStoreSlice'
import BrevoTransactionalMessageEventDataStoreSlice from '../slices/data/BrevoTransactionalMessageEventDataStoreSlice'
import LinkCategoryDataStoreSlice from '../slices/data/LinkCategoryDataStoreSlice'
import SalesAgentDataStoreSlice from '../slices/data/SalesAgentDataStoreSlice'
import ClickSyncProcessDataStoreSlice from '../slices/data/ClickSyncProcessDataStoreSlice'
import ClickSyncProcessLogDataStoreSlice from '../slices/data/ClickSyncProcessLogDataStoreSlice'
import ZoneDataStoreSlice from '../slices/data/ZoneDataStoreSlice'
import DataStoresSaga from './data/DataStores'
import AuthExperienceSaga from './experiences/Auth'
import UserManagementExperienceSaga from './experiences/UserManagement'
import LinkManagementExperienceSaga from './experiences/LinkManagement'
import ClickManagementExperienceSaga from './experiences/ClickManagement'
import ApiKeyManagementExperienceSaga from './experiences/ApiKeyManagement'
import GloballyExcludedClickerManagementExperienceSaga from './experiences/GloballyExcludedClickerManagement'
import MessageClientManagementExperienceSaga from './experiences/MessageClientManagement'
import DashboardExperienceSaga from './experiences/Dashboard'
import ClickSyncProcessManagementExperienceSaga from './experiences/ClickSyncProcessManagement'
import ClickSyncProcessLogManagementExperienceSaga from './experiences/ClickSyncProcessLogManagement'
import ClickSyncSettingsExperienceSaga from './experiences/ClickSyncSettings'
import ZoneManagementExperienceSaga from './experiences/ZoneManagement'

export default function * rootSaga () {
  yield all([
    fork(ErrorSaga),
    fork(UserDataStoreSlice.saga),
    fork(LinkDataStoreSlice.saga),
    fork(ClickDataStoreSlice.saga),
    fork(LanguageDataStoreSlice.saga),
    fork(ApiKeyDataStoreSlice.saga),
    fork(GloballyExcludedClickerDataStoreSlice.saga),
    fork(MessageClientDataStoreSlice.saga),
    fork(BrevoTransactionalMessageEventDataStoreSlice.saga),
    fork(LinkCategoryDataStoreSlice.saga),
    fork(SalesAgentDataStoreSlice.saga),
    fork(ClickSyncProcessDataStoreSlice.saga),
    fork(ClickSyncProcessLogDataStoreSlice.saga),
    fork(ZoneDataStoreSlice.saga),
    fork(DataStoresSaga),
    fork(AuthExperienceSaga),
    fork(UserManagementExperienceSaga),
    fork(LinkManagementExperienceSaga),
    fork(ClickManagementExperienceSaga),
    fork(ApiKeyManagementExperienceSaga),
    fork(GloballyExcludedClickerManagementExperienceSaga),
    fork(MessageClientManagementExperienceSaga),
    fork(DashboardExperienceSaga),
    fork(ClickSyncProcessManagementExperienceSaga),
    fork(ClickSyncProcessLogManagementExperienceSaga),
    fork(ClickSyncSettingsExperienceSaga),
    fork(ZoneManagementExperienceSaga)
  ])
}
