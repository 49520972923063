const CLICK_SYNC_EXECUTION_STATUS = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  NOTHING_TO_SYNC: 'NOTHING_TO_SYNC'
}

export const CLICK_SYNC_EXECUTION_STATUS_COLORS = {
  [CLICK_SYNC_EXECUTION_STATUS.SUCCESS]: 'success',
  [CLICK_SYNC_EXECUTION_STATUS.FAILED]: 'error',
  [CLICK_SYNC_EXECUTION_STATUS.NOTHING_TO_SYNC]: 'info'
}

export default CLICK_SYNC_EXECUTION_STATUS
