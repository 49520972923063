import { combineReducers } from 'redux'
import ClickSyncProcessManagementClickSyncProcessListSlice from '../../slices/experiences/ClickSyncProcessManagementClickSyncProcessListSlice'
import ClickSyncProcessManagementClickSyncProcessListExportSlice from '../../slices/experiences/ClickSyncProcessManagementClickSyncProcessListExportSlice'

const ClickSyncProcessManagementExperienceStore = combineReducers({
  list: ClickSyncProcessManagementClickSyncProcessListSlice.reducer,
  exportList: ClickSyncProcessManagementClickSyncProcessListExportSlice.reducer
})

export default ClickSyncProcessManagementExperienceStore
