import LinkCategoryService from '../../../services/LinkCategory'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const LinkCategoryDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'linkCategory',
  LinkCategoryService,
  RESTModelNormalizationSchemas.linkCategory.schema,
  [RESTModelNormalizationSchemas.linkCategory.entityName]
)

export default LinkCategoryDataStoreSagaHandlers
