import ClickSyncProcessDataStoreSelectors from '../selectors/data/ClickSyncProcesses'
import ClickSyncProcessDataStoreSlice from '../slices/data/ClickSyncProcessDataStoreSlice'
import StoreClickSyncProcess from '../models/ClickSyncProcess'
import useDataStoreItems from './helpers/useDataStoreItems'

const JSONStoreClickSyncProcessToStoreClickSyncProcess = JSONStoreClickSyncProcess => StoreClickSyncProcess.FromJSON(JSONStoreClickSyncProcess)

/**
 * Returns requested-/all clickSyncProcess(s) from state.data.clickSyncProcesses.
 * @param clickSyncProcessIds {string | string[]}: id of requested clickSyncProcesses.
 * @param asMap {boolean}: when multiple clickSyncProcesses are requested, return clickSyncProcesses as an object mapping clickSyncProcesses by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all clickSyncProcesses, when no clickSyncProcess id is passed.
 * @returns {StoreClickSyncProcess | StoreClickSyncProcess[]}
 */
const useNormalizedClickSyncProcesses = (
  clickSyncProcessIds = null,
  asMap = false,
  fetchAllEnabled = false
) => useDataStoreItems(
  clickSyncProcessIds,
  ClickSyncProcessDataStoreSelectors.clickSyncProcesses,
  ClickSyncProcessDataStoreSlice.actions.fetch,
  JSONStoreClickSyncProcessToStoreClickSyncProcess,
  asMap,
  fetchAllEnabled
)

export default useNormalizedClickSyncProcesses
