import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Search for the number of links created under a certain period of time. Get results grouped by link category.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindManyCreatedByLinkCategory = async listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.LINK_STATISTICS.CREATED_BY_CATEGORY,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

/**
 * Search for the number of clicks on links created under a certain period of time. Get results grouped by link category.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindManyClicksOnLinksCreatedByLinkCategory = async listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.LINK_STATISTICS.CREATED_BY_CATEGORY_NUMBER_OF_CLICKS,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

/**
 * Search for the number of clicks under a certain period of time.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindManyClicksOnLinksByPeriod = async listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.LINK_STATISTICS.NUMBER_OF_CLICKS_BY_PERIOD,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const LinkStatisticsService = {
  FindManyCreatedByLinkCategory,
  FindManyClicksOnLinksCreatedByLinkCategory,
  FindManyClicksOnLinksByPeriod
}

export default LinkStatisticsService
