import { combineReducers } from 'redux'
import DashboardLinksCreatedByCategoryListSlice
  from '../../slices/experiences/DashboardLinksCreatedByCategoryListSlice'
import DashboardClicksOnLinksCreatedByCategoryListSlice
  from '../../slices/experiences/DashboardClicksOnLinksCreatedByCategoryListSlice'
import DashboardClicksOnLinksByPeriodListSlice from '../../slices/experiences/DashboardClicksOnLinksByPeriodListSlice'

const DashboardExperienceStore = combineReducers({
  linksCreatedByCategory: DashboardLinksCreatedByCategoryListSlice.reducer,
  clicksOnLinksCreatedByCategory: DashboardClicksOnLinksCreatedByCategoryListSlice.reducer,
  clicksOnLinksByPeriod: DashboardClicksOnLinksByPeriodListSlice.reducer
})

export default DashboardExperienceStore
