import ClickSyncProcessLogService from '../../../services/ClickSyncProcessLog'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const ClickSyncProcessLogDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'clickSyncProcessLog',
  ClickSyncProcessLogService,
  RESTModelNormalizationSchemas.clickSyncProcessLog.schema,
  [
    RESTModelNormalizationSchemas.clickSyncProcessLog.entityName,
    RESTModelNormalizationSchemas.clickSyncProcess.entityName
  ]
)

export default ClickSyncProcessLogDataStoreSagaHandlers
