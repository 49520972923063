import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import useDataStoreItemDetails from './helpers/useDataStoreItemDetails'
import CLICK_SYNC_EXECUTION_STATUS, {
  CLICK_SYNC_EXECUTION_STATUS_COLORS
} from '../models/enums/ClickSyncExecutionStatus'

/**
 * Provides access to the click sync execution statuses available inside the application.
 * @returns {{id: string, name: string}[] | { [string]: {id: string, name: string} }}: click sync execution statuses in
 * a displayable form(click sync execution status
 * names are already translated)
 */
const useClickSyncExecutionStatuses = (clickSyncExecutionStatusIds = null, asMap = false) => {
  const { t } = useTranslation()

  const mapClickSyncExecutionStatusToReturnedClickSyncExecutionStatus = useCallback(
    clickSyncExecutionStatus => ({
      id: clickSyncExecutionStatus,
      name: t(`CLICK_SYNC_EXECUTION_STATUS['${clickSyncExecutionStatus}']`, clickSyncExecutionStatus),
      color: CLICK_SYNC_EXECUTION_STATUS_COLORS[clickSyncExecutionStatus]
    }),
    [t]
  )

  return useDataStoreItemDetails(
    CLICK_SYNC_EXECUTION_STATUS,
    clickSyncExecutionStatusIds,
    asMap,
    mapClickSyncExecutionStatusToReturnedClickSyncExecutionStatus,
    true
  )
}

export default useClickSyncExecutionStatuses
