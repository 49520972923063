import SalesAgentService from '../../../services/SalesAgent'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const SalesAgentDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'salesAgent',
  SalesAgentService,
  RESTModelNormalizationSchemas.salesAgent.schema,
  [RESTModelNormalizationSchemas.salesAgent.entityName]
)

export default SalesAgentDataStoreSagaHandlers
