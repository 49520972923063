import ClickSyncProcessService from '../../../services/ClickSyncProcess'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const ClickSyncProcessDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'clickSyncProcess',
  ClickSyncProcessService,
  RESTModelNormalizationSchemas.clickSyncProcess.schema,
  [
    RESTModelNormalizationSchemas.clickSyncProcess.entityName
  ]
)

export default ClickSyncProcessDataStoreSagaHandlers
