import {
  format as dateFnsFormat,
  parseISO as dateFnsParseISO,
  parse as dateFnsParse,
  addMilliseconds as dateFnsAddMilliseconds,
  addSeconds as dateFnsAddSeconds,
  addMinutes as dateFnsAddMinutes,
  addHours as dateFnsAddHours,
  addDays as dateFnsAddDays,
  addWeeks as dateFnsAddWeeks,
  addMonths as dateFnsAddMonths,
  addYears as dateFnsAddYears,
  differenceInMilliseconds as dateFnsDiffInMS,
  differenceInSeconds as dateFnsDiffInSeconds,
  differenceInMinutes as dateFnsDiffInMinutes,
  differenceInHours as dateFnsDiffInHours,
  differenceInYears as dateFnsDiffInYears,
  eachDayOfInterval as dateFnsEachDayOfInterval,
  eachWeekOfInterval as dateFnsEachWeekOfInterval,
  formatRelative as dateFnsFormatRelative,
  formatDuration as dateFnsFormatDuration,
  formatDistance as dateFnsFormatDistance,
  isSameDay as dateFnsIsSameDay
} from 'date-fns'
import { SUPPORTED_LOCALES, VALUE_FORMAT_TYPES } from '../i18n/constants'
import i18next from 'i18next'
import { IS_DEV_ENV } from '../../constants/general'
import { enGB, enUS, fr } from 'date-fns/locale'

// core
export const format = (date, format) => dateFnsFormat(date, format)

export const parseISO = date => dateFnsParseISO(date)

export const parse = (dateString, formatString, referenceDate = new Date()) => dateFnsParse(dateString, formatString, referenceDate)

export const addMilliseconds = (date, milliseconds) => dateFnsAddMilliseconds(date, milliseconds)

export const addSeconds = (date, seconds) => dateFnsAddSeconds(date, seconds)

export const addMinutes = (date, minutes) => dateFnsAddMinutes(date, minutes)

export const addHours = (date, hours) => dateFnsAddHours(date, hours)

export const addDays = (date, days) => dateFnsAddDays(date, days)

export const addWeeks = (date, weeks) => dateFnsAddWeeks(date, weeks)

export const addMonths = (date, months) => dateFnsAddMonths(date, months)

export const addYears = (date, years) => dateFnsAddYears(date, years)

export const differenceInMilliseconds = (date1, date2) => dateFnsDiffInMS(date1, date2)

export const differenceInSeconds = (date1, date2) => dateFnsDiffInSeconds(date1, date2)

export const differenceInMinutes = (date1, date2) => dateFnsDiffInMinutes(date1, date2)

export const differenceInHours = (date1, date2) => dateFnsDiffInHours(date1, date2)

export const differenceInYears = (date1, date2) => dateFnsDiffInYears(date1, date2)

export const eachDayOfInterval = (intervalStartDate, intervalEndDate) => dateFnsEachDayOfInterval({
  start: intervalStartDate,
  end: intervalEndDate
})?.map(date => new Date(
  Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()) // Adjust each date to UTC
)
)

export const eachWeekOfInterval = (intervalStartDate, intervalEndDate) => dateFnsEachWeekOfInterval({
  start: intervalStartDate,
  end: intervalEndDate
},
{
  weekStartsOn: 2
}
)?.map(date => new Date(
  Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()) // Adjust each date to UTC
)
)

export const formatRelative = (date, baseDate) => dateFnsFormatRelative(date, baseDate)

export const formatDuration = duration => dateFnsFormatDuration(duration)

export const formatDistance = (date1, date2, options = {
  addSuffix: true,
  includeSeconds: true
}) => dateFnsFormatDistance(date1, date2, options)

export const isSameDay = (date1, date2) => dateFnsIsSameDay(date1, date2)

// most used date format strings across the application
export const DATE_FORMAT_STRINGS = {
  [`${SUPPORTED_LOCALES.EN_GB}_${VALUE_FORMAT_TYPES.DEFAULT_DATE}`]: 'dd/MM/yyyy HH:mm',
  [`${SUPPORTED_LOCALES.EN_US}_${VALUE_FORMAT_TYPES.DEFAULT_DATE}`]: 'dd/MM/yyyy HH:mm',
  [`${SUPPORTED_LOCALES.FR}_${VALUE_FORMAT_TYPES.DEFAULT_DATE}`]: 'MM/dd/yyyy HH:mm',
  [`${SUPPORTED_LOCALES.EN_GB}_${VALUE_FORMAT_TYPES.SHORT_DATE}`]: 'dd/MM/yyyy',
  [`${SUPPORTED_LOCALES.EN_US}_${VALUE_FORMAT_TYPES.SHORT_DATE}`]: 'dd/MM/yyyy',
  [`${SUPPORTED_LOCALES.FR}_${VALUE_FORMAT_TYPES.SHORT_DATE}`]: 'MM/dd/yyyy'
}

/**
 * Format a date with the most used format variants across the application that matches the current or the enforced
 * locale.
 * @param date {Date}: Date being formatted.
 * @param fallbackResult {string}: Result returned when the format fails.
 * @param variant {VALUE_FORMAT_TYPES}: format variant.
 * @param locale {SUPPORTED_LOCALES}: enforced locale. In case of omission, date is formatted
 * according to the current locale.
 * @return {string}: Formatted date.
 */
export const formatDate = (
  date,
  fallbackResult = '',
  variant = VALUE_FORMAT_TYPES.DEFAULT_DATE,
  locale = null
) => {
  const usedLocale = locale || i18next.language
  try {
    return format(date, DATE_FORMAT_STRINGS[`${usedLocale}_${variant}`])
  } catch (e) {
    if (IS_DEV_ENV) {
      console.warn('[DateUtilities->formatDate] Couldn\'t format date:', date)
    }
  }

  return fallbackResult
}

// custom date format functions for the i18n module
export const customDateFormatFunctions = {
  [`${VALUE_FORMAT_TYPES.DEFAULT_DATE}_${SUPPORTED_LOCALES.EN_GB}`]: date => formatDate(
    date,
    '',
    VALUE_FORMAT_TYPES.DEFAULT_DATE,
    SUPPORTED_LOCALES.EN_GB
  ),
  [`${VALUE_FORMAT_TYPES.DEFAULT_DATE}_${SUPPORTED_LOCALES.EN_US}`]: date => formatDate(
    date,
    '',
    VALUE_FORMAT_TYPES.DEFAULT_DATE,
    SUPPORTED_LOCALES.EN_US
  ),
  [`${VALUE_FORMAT_TYPES.DEFAULT_DATE}_${SUPPORTED_LOCALES.FR}`]: date => formatDate(
    date,
    '',
    VALUE_FORMAT_TYPES.DEFAULT_DATE,
    SUPPORTED_LOCALES.FR
  ),
  [`${VALUE_FORMAT_TYPES.SHORT_DATE}_${SUPPORTED_LOCALES.EN_GB}`]: date => formatDate(
    date,
    '',
    VALUE_FORMAT_TYPES.SHORT_DATE,
    SUPPORTED_LOCALES.EN_GB
  ),
  [`${VALUE_FORMAT_TYPES.SHORT_DATE}_${SUPPORTED_LOCALES.EN_US}`]: date => formatDate(
    date,
    '',
    VALUE_FORMAT_TYPES.SHORT_DATE,
    SUPPORTED_LOCALES.EN_US
  ),
  [`${VALUE_FORMAT_TYPES.SHORT_DATE}_${SUPPORTED_LOCALES.FR}`]: date => formatDate(
    date,
    '',
    VALUE_FORMAT_TYPES.SHORT_DATE,
    SUPPORTED_LOCALES.FR
  )
}

/**
 * Creates a date formatter function that can be passed to columns as a formatter. It serves to reduce boilerplate
 * as formatting date members inside datatables is very similar.
 * @param attributeName {string}: the name of the attribute the date is extracted from the row item. It doesn't have any
 * effect when @param valueExtractor is specified.
 * @param fallbackResult {string}: string returned when the format fails.
 * @param forExport {boolean}: format date for export or for display. The row data-structure is different in case of
 * export, so the data is extracted differently. It doesn't have any effect when @param valueExtractor is specified.
 * @param formatVariant {VALUE_FORMAT_TYPES}: date format variant.
 * @param valueExtractor {function({}): Date}: custom function for extracting the date from the row item(in case @param
 * attributeName can't be used)
 * @return {function(*): string}
 */
export const createExtendedDataGridDateFormatter = (
  attributeName,
  fallbackResult = '-',
  forExport = false,
  formatVariant = VALUE_FORMAT_TYPES.DEFAULT_DATE,
  valueExtractor = null
) => dataRow => {
  const subject = (
    valueExtractor && valueExtractor(dataRow)
  ) || (
    forExport
      ? dataRow[attributeName]
      : dataRow.row[attributeName]
  )

  return subject
    ? formatDate(
      subject,
      fallbackResult,
      formatVariant
    )
    : fallbackResult
}

export const getDateLocaleByLocaleName = localeName => {
  switch (localeName) {
    case SUPPORTED_LOCALES.EN_GB:
      return enGB
    case SUPPORTED_LOCALES.FR:
      return fr
    case SUPPORTED_LOCALES.EN_US:
    default:
      return enUS
  }
}

/** Returns a new date whose value is the start of the provided date in the UTC timezone. **/
export const getTheBeginningOfTheDay = date => {
  const returnedDate = new Date(date.getTime())
  returnedDate.setUTCHours(0)
  returnedDate.setUTCMinutes(0)
  returnedDate.setUTCSeconds(0)
  returnedDate.setUTCMilliseconds(0)

  return returnedDate
}
