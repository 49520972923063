const selectSalesAgents = state => state.data.salesAgents

const selectSalesAgentById = salesAgentId => state => state.data.salesAgents[salesAgentId]

const SalesAgentDataStoreSelectors = {
  salesAgents: selectSalesAgents,
  salesAgentById: selectSalesAgentById
}

export default SalesAgentDataStoreSelectors
