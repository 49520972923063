import BrevoTransactionalMessageEventDataStoreSelectors from '../selectors/data/BrevoTransactionalMessageEvents'
import BrevoTransactionalMessageEventDataStoreSlice from '../slices/data/BrevoTransactionalMessageEventDataStoreSlice'
import StoreBrevoTransactionalMessageEvent from '../models/BrevoTransactionalMessageEvent'
import useDataStoreItems from './helpers/useDataStoreItems'

const JSONStoreBrevoTransactionalMessageEventToStoreBrevoTransactionalMessageEvent = JSONStoreBrevoTransactionalMessageEvent => StoreBrevoTransactionalMessageEvent.FromJSON(JSONStoreBrevoTransactionalMessageEvent)

/**
 * Returns requested-/all brevo transactional message event(s) from state.data.brevoTransactionalMessageEvents.
 * @param brevoTransactionalMessageEventIds {string | string[]}: id of requested brevo transactional message events.
 * @param asMap {boolean}: when multiple brevo transactional message events are requested, return brevo transactional message events as an object mapping brevo transactional message events by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all brevo transactional message events, when no brevo transactional message event id is passed.
 * @returns {StoreBrevoTransactionalMessageEvent | StoreBrevoTransactionalMessageEvent[]}
 */
const useNormalizedBrevoTransactionalMessageEvents = (
  brevoTransactionalMessageEventIds = null,
  asMap = false,
  fetchAllEnabled = false
) => useDataStoreItems(
  brevoTransactionalMessageEventIds,
  BrevoTransactionalMessageEventDataStoreSelectors.brevoTransactionalMessageEvents,
  BrevoTransactionalMessageEventDataStoreSlice.actions.fetch,
  JSONStoreBrevoTransactionalMessageEventToStoreBrevoTransactionalMessageEvent,
  asMap,
  fetchAllEnabled
)

export default useNormalizedBrevoTransactionalMessageEvents
