import BrevoTransactionalMessageEventService from '../../../services/BrevoTransactionalMessageEvent'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const BrevoTransactionalMessageEventDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'brevoTransactionalMessageEvent',
  BrevoTransactionalMessageEventService,
  RESTModelNormalizationSchemas.brevoTransactionalMessageEvent.schema,
  [
    RESTModelNormalizationSchemas.brevoTransactionalMessageEvent.entityName
  ]
)

export default BrevoTransactionalMessageEventDataStoreSagaHandlers
