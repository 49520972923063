import { put, takeLatest } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import DashboardLinksCreatedByCategoryListSlice
  from '../../slices/experiences/DashboardLinksCreatedByCategoryListSlice'
import DashboardExperienceActions from '../../actions/experiences/Dashboard'
import LinkStatisticsService from '../../../services/LinkStatistics'
import ListMetaData from '../../../modules/utilities/list/ListMetaData'
import FilterExpression from '../../../modules/utilities/list/FilterExpression'
import DashboardClicksOnLinksCreatedByCategoryListSlice
  from '../../slices/experiences/DashboardClicksOnLinksCreatedByCategoryListSlice'
import DashboardClicksOnLinksByPeriodListSlice from '../../slices/experiences/DashboardClicksOnLinksByPeriodListSlice'

function * fetchLinksCreatedByCategory (action) {
  const sliceActions = DashboardExperienceActions.linksCreatedByCategory
  try {
    const { data: { limit, page, sort, filters, filtersLinkingType } } = action
    yield put(sliceActions.setIsLoading(true))

    const result = yield LinkStatisticsService.FindManyCreatedByLinkCategory(
      new ListMetaData(
        limit,
        page,
        sort,
        new FilterExpression(
          filtersLinkingType,
          ...filters
        )
      )
    )

    yield put(
      sliceActions.setData(
        result.data.items.map(statisticsItem => ({
          id: uuidv4(),
          ...statisticsItem,
          date: new Date(statisticsItem.date)
        })),
        result.data.totalNrOfItems,
        false
      )
    )
  } catch (e) {
    yield put(sliceActions.setIsLoading(false))
    yield put(ErrorActions.trigger(
      new OLError('[DashboardExperienceSaga->fetchLinksCreatedByCategory]: Failed to fetch link statistics',
        'Failed to fetch link statistics', e),
      true,
      true,
      true,
      false
    ))
  }
}

function * fetchClicksOnLinksCreatedByCategory (action) {
  const sliceActions = DashboardExperienceActions.clicksOnLinksCreatedByCategory
  try {
    const { data: { limit, page, sort, filters, filtersLinkingType } } = action
    yield put(sliceActions.setIsLoading(true))

    const result = yield LinkStatisticsService.FindManyClicksOnLinksCreatedByLinkCategory(
      new ListMetaData(
        limit,
        page,
        sort,
        new FilterExpression(
          filtersLinkingType,
          ...filters
        )
      )
    )

    yield put(
      sliceActions.setData(
        result.data.items.map(statisticsItem => ({
          id: uuidv4(),
          ...statisticsItem,
          date: new Date(statisticsItem.date)
        })),
        result.data.totalNrOfItems,
        false
      )
    )
  } catch (e) {
    yield put(sliceActions.setIsLoading(false))
    yield put(ErrorActions.trigger(
      new OLError('[DashboardExperienceSaga->fetchClicksOnLinksCreatedByCategory]: Failed to fetch link statistics',
        'Failed to fetch link statistics', e),
      true,
      true,
      true,
      false
    ))
  }
}

function * fetchClicksOnLinksByPeriod (action) {
  const sliceActions = DashboardExperienceActions.clicksOnLinksByPeriod
  try {
    const { data: { limit, page, sort, filters, filtersLinkingType } } = action
    yield put(sliceActions.setIsLoading(true))

    const result = yield LinkStatisticsService.FindManyClicksOnLinksByPeriod(
      new ListMetaData(
        limit,
        page,
        sort,
        new FilterExpression(
          filtersLinkingType,
          ...filters
        )
      )
    )

    yield put(
      sliceActions.setData(
        result.data.items.map(statisticsItem => ({
          id: uuidv4(),
          ...statisticsItem,
          date: new Date(statisticsItem.date)
        })),
        result.data.totalNrOfItems,
        false
      )
    )
  } catch (e) {
    yield put(sliceActions.setIsLoading(false))
    yield put(ErrorActions.trigger(
      new OLError('[DashboardExperienceSaga->fetchClicksOnLinksByPeriod]: Failed to fetch link statistics',
        'Failed to fetch link statistics', e),
      true,
      true,
      true,
      false
    ))
  }
}

function * DashboardExperienceSaga () {
  yield takeLatest(DashboardLinksCreatedByCategoryListSlice.types.FETCH, fetchLinksCreatedByCategory)
  yield takeLatest(DashboardClicksOnLinksCreatedByCategoryListSlice.types.FETCH, fetchClicksOnLinksCreatedByCategory)
  yield takeLatest(DashboardClicksOnLinksByPeriodListSlice.types.FETCH, fetchClicksOnLinksByPeriod)
}

export default DashboardExperienceSaga
