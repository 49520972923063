import { put, takeLatest } from 'redux-saga/effects'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import LayoutActions from '../../actions/Layout'
import CLICK_SYNC_SETTINGS_EXPERIENCE_TYPES from '../../types/experiences/ClickSyncSettings'
import MessageActions from '../../actions/Messages'
import { AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT, DISPLAYED_MESSAGE_TYPES } from '../../../constants/general'
import ClickSyncSettingsService from '../../../services/ClickSyncSettings'
import ClickSyncSettingsExperienceActions from '../../actions/experiences/ClickSyncSettings'

function * fetchClickSyncSettings () {
  const fetchId = 'ClickSyncSettingsExperienceSaga.fetchClickSyncSettings'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const result = yield ClickSyncSettingsService.Get()

    if (result.status === 200) {
      yield put(
        ClickSyncSettingsExperienceActions.set(result.data)
      )
    } else {
      throw new Error('Invalid http response received')
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[ClickSyncSettingsExperience->fetchClickSyncSettings]: Failed to fetch click sync settings',
          'Failed to fetch click sync settings',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * saveClickSyncSettings (action) {
  const fetchId = 'ClickSyncSettingsExperienceSaga.saveClickSyncSettings'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { settingsItem } } = action
    const result = yield ClickSyncSettingsService.Save(settingsItem.toNormalizedRESTModel())

    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['SAVE_CLICK_SYNC_SETTINGS_SUCCESS', 'Click sync settings successfully saved'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      // update click sync settings store value with the updated click sync settings value
      yield put(
        ClickSyncSettingsExperienceActions.set(result.data)
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[ClickSyncSettingsExperience->saveClickSyncSettings]: Failed to save click sync settings',
          'Failed to save click sync settings',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * ClickSyncSettingsExperienceSaga () {
  yield takeLatest(CLICK_SYNC_SETTINGS_EXPERIENCE_TYPES.FETCH, fetchClickSyncSettings)
  yield takeLatest(CLICK_SYNC_SETTINGS_EXPERIENCE_TYPES.SAVE, saveClickSyncSettings)
}

export default ClickSyncSettingsExperienceSaga
