import MessageClientService from '../../../services/MessageClient'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const MessageClientDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'messageClient',
  MessageClientService,
  RESTModelNormalizationSchemas.messageClient.schema,
  [
    RESTModelNormalizationSchemas.messageClient.entityName,
    RESTModelNormalizationSchemas.salesAgent.entityName
  ]
)

export default MessageClientDataStoreSagaHandlers
