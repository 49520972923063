import { put, takeLatest } from 'redux-saga/effects'
import ClickSyncProcessLogService from '../../../services/ClickSyncProcessLog'
import ListMetaData from '../../../modules/utilities/list/ListMetaData'
import FilterExpression from '../../../modules/utilities/list/FilterExpression'
import ClickSyncProcessLogDataStoreSlice from '../../slices/data/ClickSyncProcessLogDataStoreSlice'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import ClickSyncProcessLogManagementExperienceActions from '../../actions/experiences/ClickSyncProcessLogManagement'
import ClickSyncProcessLogManagementClickSyncProcessLogListSlice
  from '../../slices/experiences/ClickSyncProcessLogManagementClickSyncProcessLogListSlice'
import ClickSyncProcessLogManagementClickSyncProcessLogListExportSlice
  from '../../slices/experiences/ClickSyncProcessLogManagementClickSyncProcessLogListExportSlice'

function * fetchClickSyncProcessLogs (action) {
  const sliceActions = action.type === ClickSyncProcessLogManagementClickSyncProcessLogListExportSlice.types.FETCH
    ? ClickSyncProcessLogManagementExperienceActions.exportList
    : ClickSyncProcessLogManagementExperienceActions.list
  try {
    const { data: { limit, page, sort, filters, filtersLinkingType } } = action
    yield put(sliceActions.setIsLoading(true))

    const result = yield ClickSyncProcessLogService.FindMany(
      new ListMetaData(
        limit,
        page,
        sort,
        new FilterExpression(
          filtersLinkingType,
          ...filters
        )
      )
    )
    yield put(
      ClickSyncProcessLogDataStoreSlice.actions.fetchResult(
        result,
        function * (storeValues) {
          yield put(
            sliceActions.setData(storeValues.result, storeValues.totalNrOfItems, false)
          )
        }
      )
    )
  } catch (e) {
    yield put(sliceActions.setIsLoading(false))
    yield put(ErrorActions.trigger(
      new OLError(
        '[ClickSyncProcessLogManagementExperience->fetchClickSyncProcessLogs]: Failed to fetch click sync process logs',
        'Failed to fetch clickSyncProcessLogs',
        e
      ),
      true,
      true,
      true,
      false
    ))
  }
}

function * ClickSyncProcessLogManagementExperienceSaga () {
  yield takeLatest(ClickSyncProcessLogManagementClickSyncProcessLogListSlice.types.FETCH, fetchClickSyncProcessLogs)
  yield takeLatest(ClickSyncProcessLogManagementClickSyncProcessLogListExportSlice.types.FETCH, fetchClickSyncProcessLogs)
}

export default ClickSyncProcessLogManagementExperienceSaga
