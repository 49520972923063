import { useMemo } from 'react'
import useNormalizedZones from './useNormalizedZones'

/**
 * Returns requested-/all zone(s) from state.data.zones in a non-normalized fashion.
 * @param zoneIds {string | string[]}: id of requested zones.
 * @param asMap {boolean}: when multiple zones are requested, return zones as an object mapping zones by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all items, when no itemId is passed
 * @returns {StoreZone | StoreZone[]}
 */
const useZones = (zoneIds = null, asMap = false, fetchAllEnabled = false) => {
  const normalizedZones = useNormalizedZones(zoneIds, asMap, fetchAllEnabled)

  return useMemo(
    () => {
      const populateZone = zone => zone

      return asMap
        ? Object.keys(normalizedZones)
          .reduce((zones, zoneId) => {
            zones[zoneId] = populateZone(normalizedZones[zoneId])
            return zones
          }, {})
        : normalizedZones.map(populateZone)
    },
    [normalizedZones, asMap]
  )
}

export default useZones
