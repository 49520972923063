import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useNormalizedSalesAgents from './useNormalizedSalesAgents'
import { ALL_VALUES_SELECT_OPTION_VALUE } from '../../constants/general'

const salesAgentToSelectOption = ({ id, name }) => ({
  value: id,
  label: name
})

/**
 * Use sales agents as select/radio input options
 * @param includeAllAgentsOption {boolean}: whether to include the 'All agents' option
 * @returns {[{value: any, label: string}]}
 */
const useSalesAgentsAsSelectOptions = (includeAllAgentsOption = true) => {
  const salesAgents = useNormalizedSalesAgents()
  const { t } = useTranslation()

  return useMemo(() => {
    const salesAgentSelectOptions = salesAgents.map(salesAgentToSelectOption)
    return includeAllAgentsOption
      ? ([
          {
            value: ALL_VALUES_SELECT_OPTION_VALUE,
            label: t('SalesAgentSelectOptions.AllAgents', 'All agents')
          },
          ...salesAgentSelectOptions
        ])
      : salesAgentSelectOptions
  }, [salesAgents, includeAllAgentsOption, t])
}

export default useSalesAgentsAsSelectOptions
