import { commonHandlers, createReducer } from '../../../modules/utilities/store'
import GLOBAL_TYPES from '../../types/Global'
import CLICK_SYNC_SETTINGS_EXPERIENCE_TYPES from '../../types/experiences/ClickSyncSettings'

const initialState = {
  settings: null
}

const handlers = {
  [CLICK_SYNC_SETTINGS_EXPERIENCE_TYPES.SET]: commonHandlers.setValue('settings'),
  [CLICK_SYNC_SETTINGS_EXPERIENCE_TYPES.RESET]: commonHandlers.setValue('settings'),
  [GLOBAL_TYPES.RESET_STORE]: commonHandlers.resetState(initialState)
}

const ClickSyncSettingsExperienceStore = createReducer(initialState, handlers)

export default ClickSyncSettingsExperienceStore
