import { combineReducers } from 'redux'
import UserDataStoreSlice from '../../slices/data/UserDataStoreSlice'
import LinkDataStoreSlice from '../../slices/data/LinkDataStoreSlice'
import ClickDataStoreSlice from '../../slices/data/ClickDataStoreSlice'
import LanguageDataStoreSlice from '../../slices/data/LanguageDataStoreSlice'
import ApiKeyDataStoreSlice from '../../slices/data/ApiKeyDataStoreSlice'
import GloballyExcludedClickerDataStoreSlice from '../../slices/data/GloballyExcludedClickerDataStoreSlice'
import MessageClientDataStoreSlice from '../../slices/data/MessageClientDataStoreSlice'
import BrevoTransactionalMessageEventDataStoreSlice
  from '../../slices/data/BrevoTransactionalMessageEventDataStoreSlice'
import LinkCategoryDataStoreSlice from '../../slices/data/LinkCategoryDataStoreSlice'
import SalesAgentDataStoreSlice from '../../slices/data/SalesAgentDataStoreSlice'
import ClickSyncProcessDataStoreSlice from '../../slices/data/ClickSyncProcessDataStoreSlice'
import ClickSyncProcessLogDataStoreSlice from '../../slices/data/ClickSyncProcessLogDataStoreSlice'
import ZoneDataStoreSlice from '../../slices/data/ZoneDataStoreSlice'

/**
 * Stores global state(state used by multiple experiences).
 * It is the core of the store normalization process: other experiences that use this state, reference items from this
 * store, without duplicating the same data.
 * @type {Reducer<CombinedState<{}>>}
 */
const DataStore = combineReducers({
  users: UserDataStoreSlice.reducer,
  links: LinkDataStoreSlice.reducer,
  clicks: ClickDataStoreSlice.reducer,
  languages: LanguageDataStoreSlice.reducer,
  apiKeys: ApiKeyDataStoreSlice.reducer,
  globallyExcludedClickers: GloballyExcludedClickerDataStoreSlice.reducer,
  messageClients: MessageClientDataStoreSlice.reducer,
  brevoTransactionalMessageEvents: BrevoTransactionalMessageEventDataStoreSlice.reducer,
  linkCategories: LinkCategoryDataStoreSlice.reducer,
  salesAgents: SalesAgentDataStoreSlice.reducer,
  clickSyncProcesses: ClickSyncProcessDataStoreSlice.reducer,
  clickSyncProcessLogs: ClickSyncProcessLogDataStoreSlice.reducer,
  zones: ZoneDataStoreSlice.reducer
})

export default DataStore
