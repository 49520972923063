import { put, takeLatest } from 'redux-saga/effects'
import ClickSyncProcessService from '../../../services/ClickSyncProcess'
import ListMetaData from '../../../modules/utilities/list/ListMetaData'
import FilterExpression from '../../../modules/utilities/list/FilterExpression'
import ClickSyncProcessDataStoreSlice from '../../slices/data/ClickSyncProcessDataStoreSlice'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import ClickSyncProcessManagementExperienceActions from '../../actions/experiences/ClickSyncProcessManagement'
import ClickSyncProcessManagementClickSyncProcessListSlice
  from '../../slices/experiences/ClickSyncProcessManagementClickSyncProcessListSlice'
import ClickSyncProcessManagementClickSyncProcessListExportSlice
  from '../../slices/experiences/ClickSyncProcessManagementClickSyncProcessListExportSlice'

function * fetchClickSyncProcesses (action) {
  const sliceActions = action.type === ClickSyncProcessManagementClickSyncProcessListExportSlice.types.FETCH
    ? ClickSyncProcessManagementExperienceActions.exportList
    : ClickSyncProcessManagementExperienceActions.list
  try {
    const { data: { limit, page, sort, filters, filtersLinkingType } } = action
    yield put(sliceActions.setIsLoading(true))

    const result = yield ClickSyncProcessService.FindMany(
      new ListMetaData(
        limit,
        page,
        sort,
        new FilterExpression(
          filtersLinkingType,
          ...filters
        )
      )
    )
    yield put(
      ClickSyncProcessDataStoreSlice.actions.fetchResult(
        result,
        function * (storeValues) {
          yield put(
            sliceActions.setData(storeValues.result, storeValues.totalNrOfItems, false)
          )
        }
      )
    )
  } catch (e) {
    yield put(sliceActions.setIsLoading(false))
    yield put(ErrorActions.trigger(
      new OLError(
        '[ClickSyncProcessManagementExperience->fetchClickSyncProcesses]: Failed to fetch click sync processes',
        'Failed to fetch click sync processes',
        e
      ),
      true,
      true,
      true,
      false
    ))
  }
}

function * ClickSyncProcessManagementExperienceSaga () {
  yield takeLatest(ClickSyncProcessManagementClickSyncProcessListSlice.types.FETCH, fetchClickSyncProcesses)
  yield takeLatest(ClickSyncProcessManagementClickSyncProcessListExportSlice.types.FETCH, fetchClickSyncProcesses)
}

export default ClickSyncProcessManagementExperienceSaga
