import MessageClientDataStoreSelectors from '../selectors/data/MessageClients'
import MessageClientDataStoreSlice from '../slices/data/MessageClientDataStoreSlice'
import StoreMessageClient from '../models/MessageClient'
import useDataStoreItems from './helpers/useDataStoreItems'

const JSONStoreMessageClientToStoreMessageClient = JSONStoreMessageClient => StoreMessageClient.FromJSON(JSONStoreMessageClient)

/**
 * Returns requested-/all message client(s) from state.data.messageClients.
 * @param messageClientIds {string | string[]}: id of requested message clients.
 * @param asMap {boolean}: when multiple message clients are requested, return message clients as an object mapping message clients by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all message clients, when no message client id is passed.
 * @returns {StoreMessageClient | StoreMessageClient[]}
 */
const useNormalizedMessageClients = (
  messageClientIds = null,
  asMap = false,
  fetchAllEnabled = false
) => useDataStoreItems(
  messageClientIds,
  MessageClientDataStoreSelectors.messageClients,
  MessageClientDataStoreSlice.actions.fetch,
  JSONStoreMessageClientToStoreMessageClient,
  asMap,
  fetchAllEnabled
)

export default useNormalizedMessageClients
