import ZoneManagementZoneListSlice from '../../slices/experiences/ZoneManagementZoneListSlice'
import ZONE_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/ZoneManagement'
import { makeActionCreator } from '../../../modules/utilities/store'
import ZoneManagementZoneListExportSlice from '../../slices/experiences/ZoneManagementZoneListExportSlice'

const ZoneManagementExperienceActions = {
  list: ZoneManagementZoneListSlice.actions,
  listExport: ZoneManagementZoneListExportSlice.actions,
  /**
   * @param zoneItem {StoreZone}: StoreZone instance containing edited zone data.
   * @param onSuccess {function}: executed if creation was successful.
   * @returns {{ type: string, data: { zoneItem: StoreZone, onSuccess: function } }}
   */
  create: makeActionCreator(ZONE_MANAGEMENT_EXPERIENCE_TYPES.CREATE, 'zoneItem', 'onSuccess'),
  /**
   * @param zoneItem {StoreZone}: StoreZone instance containing edited zone data.
   * @param onSuccess {function}: executed if editing was successful.
   * @returns {{ type: string, data: { zoneItem: StoreZone, onSuccess: function } }}
   */
  edit: makeActionCreator(ZONE_MANAGEMENT_EXPERIENCE_TYPES.EDIT, 'zoneItem', 'onSuccess'),
  /**
   * @param zoneId {string}: id of zone to delete.
   * @returns {{ type: string, data: { zoneId: string } }}
   */
  delete: makeActionCreator(ZONE_MANAGEMENT_EXPERIENCE_TYPES.DELETE, 'zoneId')
}

export default ZoneManagementExperienceActions
