import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Create a new link.
 * @param linkData {object}: Link data.
 * @returns {Promise<AxiosResponse<object>>}: newly created link.
 * @constructor
 */
const Create = async linkData => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.LINKS,
  {
    ...linkData,
    manuallyCreated: true
  }
)

/**
 * Update the specified link's data.
 * @param linkId {string}
 * @param linkData {object}
 * @returns {Promise<AxiosResponse<any>>}: updated link data.
 * @constructor
 */
const Update = async (linkId, linkData) => mainHTTPClient.put(
  MAIN_HTTP_API_ENDPOINTS.LINK.replace(':id', linkId),
  linkData
)

/**
 * Delete specified link.
 * @param linkId {string}
 * @returns {Promise<AxiosResponse<string>>}: OK in case the deletion was successful.
 * @constructor
 */
const Delete = async linkId => mainHTTPClient.delete(
  MAIN_HTTP_API_ENDPOINTS.LINK.replace(':id', linkId)
)

/**
 *
 * Search for links.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = async listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.LINKS,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

/**
 *
 * Search for link clicks.
 * @param linkId {string}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const GetAllClicks = async linkId => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.LINK_CLICKS.replace(':id', linkId)
)

const LinkService = {
  Create,
  Update,
  Delete,
  FindMany,
  GetAllClicks
}

export default LinkService
