const selectMessageClients = state => state.data.messageClients

const selectMessageClientById = messageClientId => state => state.data.messageClients[messageClientId]

const MessageClientDataStoreSelectors = {
  messageClients: selectMessageClients,
  messageClientById: selectMessageClientById
}

export default MessageClientDataStoreSelectors
