import ClickSyncProcessLogDataStoreSelectors from '../selectors/data/ClickSyncProcessLogs'
import ClickSyncProcessLogDataStoreSlice from '../slices/data/ClickSyncProcessLogDataStoreSlice'
import StoreClickSyncProcessLog from '../models/ClickSyncProcessLog'
import useDataStoreItems from './helpers/useDataStoreItems'

const JSONStoreClickSyncProcessLogToStoreClickSyncProcessLog = JSONStoreClickSyncProcessLog => StoreClickSyncProcessLog.FromJSON(JSONStoreClickSyncProcessLog)

/**
 * Returns requested-/all clickSyncProcessLog(s) from state.data.clickSyncProcessLogs.
 * @param clickSyncProcessLogIds {string | string[]}: id of requested clickSyncProcessLogs.
 * @param asMap {boolean}: when multiple clickSyncProcessLogs are requested, return clickSyncProcessLogs as an object mapping clickSyncProcessLogs by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all clickSyncProcessLogs, when no clickSyncProcessLog id is passed.
 * @returns {StoreClickSyncProcessLog | StoreClickSyncProcessLog[]}
 */
const useNormalizedClickSyncProcessLogs = (
  clickSyncProcessLogIds = null,
  asMap = false,
  fetchAllEnabled = false
) => useDataStoreItems(
  clickSyncProcessLogIds,
  ClickSyncProcessLogDataStoreSelectors.clickSyncProcessLogs,
  ClickSyncProcessLogDataStoreSlice.actions.fetch,
  JSONStoreClickSyncProcessLogToStoreClickSyncProcessLog,
  asMap,
  fetchAllEnabled
)

export default useNormalizedClickSyncProcessLogs
