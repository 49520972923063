import { put, takeLatest } from 'redux-saga/effects'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import LayoutActions from '../../actions/Layout'
import MESSAGE_CLIENT_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/MessageClientManagement'
import MessageClientService from '../../../services/MessageClient'
import LinkDataStoreSlice from '../../slices/data/LinkDataStoreSlice'
import BrevoTransactionalMessageEventDataStoreSlice
  from '../../slices/data/BrevoTransactionalMessageEventDataStoreSlice'

function * fetchLinks (action) {
  const fetchId = 'MessageClientManagementExperienceSaga.fetchLinks'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { messageClientId, listMetaData, onSuccess } } = action

    const result = yield MessageClientService.FindManyLinks(messageClientId, listMetaData)

    if (result.status === 200) {
      // merge links into the Link data store
      yield put(LinkDataStoreSlice.actions.fetchResult(result))

      onSuccess && onSuccess(
        result.data.items.map(link => link.id)
      )
    } else {
      throw new Error(`Invalid http response status received: ${result.status}`)
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[MessageClientManagementExperience->fetchLinks]: Failed to fetch message client links',
          'Failed to fetch message client links',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * fetchBrevoTransactionalMessageEvents (action) {
  const fetchId = 'MessageClientManagementExperienceSaga.fetchBrevoTransactionalMessageEvents'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { messageClientId, listMetaData, onSuccess } } = action

    const result = yield MessageClientService.FindManyBrevoTransactionalMessageEvents(messageClientId, listMetaData)

    if (result.status === 200) {
      // merge brevo transactional events into the Link data store
      yield put(BrevoTransactionalMessageEventDataStoreSlice.actions.fetchResult(result))

      onSuccess && onSuccess(
        result.data.items.map(brevoTransactionalMessageEventId => brevoTransactionalMessageEventId.id)
      )
    } else {
      throw new Error(`Invalid http response status received: ${result.status}`)
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[MessageClientManagementExperience->fetchBrevoTransactionalMessageEvents]: Failed to fetch message client brevo transactional message events',
          'Failed to fetch transactional message events',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * MessageClientManagementExperienceSaga () {
  yield takeLatest(MESSAGE_CLIENT_MANAGEMENT_EXPERIENCE_TYPES.FETCH_LINKS, fetchLinks)
  yield takeLatest(MESSAGE_CLIENT_MANAGEMENT_EXPERIENCE_TYPES.FETCH_BREVO_TRANSACTIONAL_MESSAGE_EVENTS, fetchBrevoTransactionalMessageEvents)
}

export default MessageClientManagementExperienceSaga
