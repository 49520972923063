const selectBrevoTransactionalMessageEvents = state => state.data.brevoTransactionalMessageEvents

const selectBrevoTransactionalMessageEventById = brevoTransactionalMessageEventId => state => state.data.brevoTransactionalMessageEvents[brevoTransactionalMessageEventId]

const BrevoTransactionalMessageEventDataStoreSelectors = {
  brevoTransactionalMessageEvents: selectBrevoTransactionalMessageEvents,
  brevoTransactionalMessageEventById: selectBrevoTransactionalMessageEventById
}

export default BrevoTransactionalMessageEventDataStoreSelectors
