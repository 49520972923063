import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import useDataStoreItemDetails from './helpers/useDataStoreItemDetails'
import { TRANSACTIONAL_MESSAGE_EVENT_ID } from '../../constants/general'

/**
 * Provides access to the transactional message events available inside the application.
 * @returns {{id: string, name: string}[] | { [string]: {id: string, name: string} }}: transactional message events in
 *  a displayable form(transactional message event names are already translated)
 */
const useTransactionalMessageEvents = (transactionalMessageEventIds = null, asMap = false) => {
  const { t } = useTranslation()

  const mapTransactionalMessageEventToReturnedTransactionalMessageEvent = useCallback(
    transactionalMessageEvent => ({
      id: transactionalMessageEvent,
      name: t(`MessageClientTransactionalEvent[${transactionalMessageEvent}]`, transactionalMessageEvent)
    }),
    [t]
  )

  return useDataStoreItemDetails(
    TRANSACTIONAL_MESSAGE_EVENT_ID, transactionalMessageEventIds, asMap,
    mapTransactionalMessageEventToReturnedTransactionalMessageEvent, true
  )
}

export default useTransactionalMessageEvents
