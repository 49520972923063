import { useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ClickSyncSettingsExperienceSelectors from '../selectors/experiences/ClickSyncSettings'
import ClickSyncSettingsExperienceActions from '../actions/experiences/ClickSyncSettings'

/**
 * Provides access to the click sync settings store value. Fetches settings if they aren't loaded yet.
 * @returns {{id: string, isEnabled: boolean, syncFrequencySec: int, syncRemoteEndpoint: string, syncRequestHeaders: string?}}:
 */
const useClickSyncSettings = () => {
  const settings = useSelector(ClickSyncSettingsExperienceSelectors.clickSyncSettings)
  const dispatch = useDispatch()

  useLayoutEffect(
    () => {
      if (!settings) {
        // fetch settings if they aren't fetched yet
        dispatch(
          ClickSyncSettingsExperienceActions.fetch()
        )
      }
    },
    [dispatch, settings]
  )

  return settings
}

export default useClickSyncSettings
