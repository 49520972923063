import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import BrevoTransactionalMessageEventDataStoreSagaHandlers from '../../sagas/data/BrevoTransactionalMessageEventDataStore'

const sliceName = 'brevoTransactionalMessageEventsStore'

const BrevoTransactionalMessageEventDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  BrevoTransactionalMessageEventDataStoreSagaHandlers
)

export default BrevoTransactionalMessageEventDataStoreSlice
