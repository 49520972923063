import { put, takeLatest } from 'redux-saga/effects'
import ZoneService from '../../../services/Zone'
import ListMetaData from '../../../modules/utilities/list/ListMetaData'
import FilterExpression from '../../../modules/utilities/list/FilterExpression'
import ZoneDataStoreSlice from '../../slices/data/ZoneDataStoreSlice'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import ZoneManagementExperienceActions from '../../actions/experiences/ZoneManagement'
import ZONE_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/ZoneManagement'
import LayoutActions from '../../actions/Layout'
import ZoneManagementZoneListSlice from '../../slices/experiences/ZoneManagementZoneListSlice'
import MessageActions from '../../actions/Messages'
import { AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT, DISPLAYED_MESSAGE_TYPES } from '../../../constants/general'
import ZoneManagementZoneListExportSlice from '../../slices/experiences/ZoneManagementZoneListExportSlice'

function * fetchZones (action) {
  const sliceActions = action.type === ZoneManagementZoneListExportSlice.types.FETCH
    ? ZoneManagementExperienceActions.listExport
    : ZoneManagementExperienceActions.list
  try {
    const { data: { limit, page, sort, filters, filtersLinkingType } } = action
    yield put(sliceActions.setIsLoading(true))

    const result = yield ZoneService.FindMany(
      new ListMetaData(
        limit,
        page,
        sort,
        new FilterExpression(
          filtersLinkingType,
          ...filters
        )
      )
    )
    yield put(
      ZoneDataStoreSlice.actions.fetchResult(
        result,
        function * (storeValues) {
          yield put(
            sliceActions.setData(storeValues.result, storeValues.totalNrOfItems, false)
          )
        }
      )
    )
  } catch (e) {
    yield put(sliceActions.setIsLoading(false))
    yield put(ErrorActions.trigger(
      new OLError('[ZoneManagementExperience->fetchZones]: Failed to fetch zones', 'Failed to fetch zones', e),
      true,
      true,
      true,
      false
    ))
  }
}

function * createZone (action) {
  const fetchId = 'ZoneManagementExperienceSaga.createZone'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { zoneItem, onSuccess } } = action

    const result = yield ZoneService.Create(zoneItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['CREATE_ZONE_SUCCESS', 'Zone successfully created'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[ZoneManagementExperience->createZone]: Failed to create zone', 'Failed to create zone', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * editZone (action) {
  const fetchId = 'ZoneManagementExperienceSaga.editZone'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { zoneItem, onSuccess } } = action

    const result = yield ZoneService.Update(zoneItem.id, zoneItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['EDIT_ZONE_SUCCESS', 'Zone successfully updated'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[ZoneManagementExperience->editZone]: Failed to update zone', 'Failed to update zone', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * deleteZone (action) {
  const fetchId = 'ZoneManagementExperienceSaga.deleteZone'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { zoneId } } = action

    const result = yield ZoneService.Delete(zoneId)
    if (result.status === 200) {
      // delete item from the store
      yield put(
        ZoneDataStoreSlice.actions.deleteItem(zoneId)
      )
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['DELETE_ZONE_SUCCESS', 'Zone deleted'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[ZoneManagementExperience->deleteZone]: Failed to delete zone', 'Failed to delete zone', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * ZoneManagementExperienceSaga () {
  yield takeLatest(ZoneManagementZoneListSlice.types.FETCH, fetchZones)
  yield takeLatest(ZoneManagementZoneListExportSlice.types.FETCH, fetchZones)
  yield takeLatest(ZONE_MANAGEMENT_EXPERIENCE_TYPES.CREATE, createZone)
  yield takeLatest(ZONE_MANAGEMENT_EXPERIENCE_TYPES.EDIT, editZone)
  yield takeLatest(ZONE_MANAGEMENT_EXPERIENCE_TYPES.DELETE, deleteZone)
}

export default ZoneManagementExperienceSaga
