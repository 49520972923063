import { put, takeLatest } from 'redux-saga/effects'
import LinkService from '../../../services/Link'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import LINK_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/LinkManagement'
import LayoutActions from '../../actions/Layout'
import MessageActions from '../../actions/Messages'
import { AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT, DISPLAYED_MESSAGE_TYPES } from '../../../constants/general'
import StoreClick from '../../models/Click'
import LinkDataStoreSlice from '../../slices/data/LinkDataStoreSlice'

function * createLink (action) {
  const fetchId = 'LinkManagementExperienceSaga.createLink'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { linkItem, onSuccess } } = action

    const result = yield LinkService.Create(linkItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['CREATE_LINK_SUCCESS', 'Link successfully created'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess(result.data)
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[LinkManagementExperience->createLink]: Failed to create link', 'Failed to create link', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * editLink (action) {
  const fetchId = 'LinkManagementExperienceSaga.editLink'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { linkItem, onSuccess } } = action
    const result = yield LinkService.Update(linkItem.id, linkItem.toNormalizedRESTModel())

    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['EDIT_LINK_SUCCESS', 'Link successfully updated'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[LinkManagementExperience->editLink]: Failed to update link', 'Failed to update link', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * deleteLink (action) {
  const fetchId = 'LinkManagementExperienceSaga.deleteLink'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { linkId } } = action

    const result = yield LinkService.Delete(linkId)
    if (result.status === 200) {
      // delete item from the store
      yield put(
        LinkDataStoreSlice.actions.deleteItem(linkId)
      )
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['DELETE_LINK_SUCCESS', 'Link deleted'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[LinkManagementExperience->deleteLink]: Failed to delete link', 'Failed to delete link', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * fetchClicks (action) {
  const fetchId = 'LinkManagementExperienceSaga.fetchClicks'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { linkId, onSuccess } } = action

    const result = yield LinkService.GetAllClicks(linkId)

    if (result.status === 200) {
      onSuccess && onSuccess(
        result.data.items.map(
          StoreClick.FromNormalizedRESTModel
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[LinkManagementExperience->fetchClicks]: Failed to fetch link clicks', 'Failed to fetch clicks', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * LinkManagementExperienceSaga () {
  yield takeLatest(LINK_MANAGEMENT_EXPERIENCE_TYPES.CREATE, createLink)
  yield takeLatest(LINK_MANAGEMENT_EXPERIENCE_TYPES.EDIT, editLink)
  yield takeLatest(LINK_MANAGEMENT_EXPERIENCE_TYPES.DELETE, deleteLink)
  yield takeLatest(LINK_MANAGEMENT_EXPERIENCE_TYPES.FETCH_CLICKS, fetchClicks)
}

export default LinkManagementExperienceSaga
