import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Search for message clients.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = async listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.MESSAGE_CLIENTS,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

/**
 * Search for links related to the specified message client.
 * @param messageClientId {string}
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindManyLinks = async (messageClientId, listMetaData) => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.MESSAGE_CLIENT_LINKS.replace(':id', messageClientId),
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

/**
 * Search for brevo transactional message events related to the specified message client.
 * @param messageClientId {string}
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindManyBrevoTransactionalMessageEvents = async (messageClientId, listMetaData) => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.MESSAGE_CLIENT_BREVO_TRANSACTIONAL_MESSAGE_EVENTS.replace(':id', messageClientId),
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const MessageClientService = {
  FindMany,
  FindManyLinks,
  FindManyBrevoTransactionalMessageEvents
}

export default MessageClientService
