class StoreClickSyncProcess {
  constructor (
    id = undefined,
    intervalStartDate,
    intervalEndDate,
    lastExecutionStatus,
    createdAt
  ) {
    this.id = id
    this.intervalStartDate = intervalStartDate
    this.intervalEndDate = intervalEndDate
    this.lastExecutionStatus = lastExecutionStatus
    this.createdAt = createdAt
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      // intervalStartDate is read-only
      // intervalEndDate is read-only
      // lastExecutionStatus is read-only
      // createdAt is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    intervalStartDate,
    intervalEndDate,
    lastExecutionStatus,
    createdAt
  }) {
    return new StoreClickSyncProcess(
      id,
      intervalStartDate,
      intervalEndDate,
      lastExecutionStatus,
      createdAt
    )
  }

  toJSON () {
    return {
      id: this.id,
      intervalStartDate: this.intervalStartDate,
      intervalEndDate: this.intervalEndDate,
      lastExecutionStatus: this.lastExecutionStatus,
      createdAt: this.createdAt
    }
  }

  static FromJSON ({
    id,
    intervalStartDate,
    intervalEndDate,
    lastExecutionStatus,
    createdAt
  }) {
    return new StoreClickSyncProcess(
      id,
      intervalStartDate,
      intervalEndDate,
      lastExecutionStatus,
      createdAt
    )
  }
}

export default StoreClickSyncProcess
