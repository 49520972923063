import useDataStoreItemsFetchedOnce from './helpers/useDataStoreItemsFetchedOnce'
import SalesAgentDataStoreSelectors from '../selectors/data/SalesAgents'
import SalesAgentDataStoreSlice from '../slices/data/SalesAgentDataStoreSlice'
import StoreSalesAgent from '../models/SalesAgent'

const JSONStoreSalesAgentToStoreSalesAgent = JSONStoreSalesAgent => StoreSalesAgent.FromJSON(JSONStoreSalesAgent)

/**
 * Returns requested-/all sales agent(s) from state.data.salesAgents.
 * @param salesAgentIds {string | string[] | null}: id of requested sales agents. If null, all sales agents are requested.
 * @param asMap {boolean}: when multiple sales agents are requested, return sales agents as an object mapping sales
 * agents by their id.
 * @returns {StoreSalesAgent | StoreSalesAgent[]}
 */
const useNormalizedSalesAgents = (salesAgentIds = null, asMap = false) => useDataStoreItemsFetchedOnce(
  salesAgentIds,
  SalesAgentDataStoreSelectors.salesAgents,
  SalesAgentDataStoreSlice.actions.fetch,
  JSONStoreSalesAgentToStoreSalesAgent,
  asMap
)

export default useNormalizedSalesAgents
